import { Add, Upload } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { ImageUploader } from "components/alove/ImageUploader";
import { OutlinedBox } from "components/alove/OutlinedBox";
import { ScreenModel } from "models/content/Screen";

const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const InstructionForm = ({
  screen,
  setScreen,
}: {
  screen: ScreenModel;
  setScreen: (screen) => void;
}) => {
  return (
    <Grid container gap={2} direction="column">
      <MDInput
        label="Title"
        fullWidth
        value={screen.title}
        onChange={(e) => {
          screen.title = e.target.value;
          setScreen({ ...screen });
        }}
      />
      {screen.instructionItems.map((instruction, index) => (
        <Grid container key={index} direction="row" bgcolor={"#f5f5f5"} p={2} gap={2}>
          <Grid item xs={6}>
            <MDInput
              label="Title"
              fullWidth
              value={instruction.title}
              onChange={(e) => {
                instruction.title = e.target.value;
                setScreen({ ...screen });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <MDInput
              label="Description"
              value={instruction.body}
              rows={4}
              fullWidth
              multiline
              onChange={(e) => {
                instruction.body = e.target.value;
                setScreen({ ...screen });
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <MDTypography variant="body2">Bad Image</MDTypography>
            <ImageUploader
              folder="instructions"
              initImage={instruction.badImage ? [instruction.badImage] : []}
              onUploaded={(badImage) => {
                if (badImage.length > 0) {
                  instruction.badImage = badImage[0];
                  setScreen({ ...screen });
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <MDTypography variant="body2">Good Image</MDTypography>
            <ImageUploader
              folder="instructions"
              initImage={instruction.goodImage ? [instruction.goodImage] : []}
              onUploaded={(goodImage) => {
                if (goodImage.length > 0) {
                  instruction.goodImage = goodImage[0];
                  setScreen({ ...screen });
                }
              }}
            />
          </Grid>
          <MDButton
            variant="contained"
            color="secondary"
            onClick={() => {
              screen.instructionItems = screen.instructionItems.filter((_, i) => i !== index);
              setScreen({ ...screen });
            }}
          >
            Delete
          </MDButton>
        </Grid>
      ))}
      <MDButton
        startIcon={<Add />}
        onClick={() => {
          screen.instructionItems.push({ title: "", body: "", badImage: "", goodImage: "" });
          setScreen({ ...screen });
        }}
      >
        Add Instruction
      </MDButton>
    </Grid>
  );
};
