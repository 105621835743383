import { CircularProgress, Divider, Grid, IconButton } from "@mui/material";
import { T } from "components/alove/Translator";
import { Introduction } from "models/introduction/Introduction";
import React, { ReactElement, useEffect, useState } from "react";
import { addMMComment, getMMList, getMMListByIDs } from "utils/matchmakerUtils";
import SendIcon from "@mui/icons-material/Send";
import { camelCaseToCapitalizedWords, formatDate, formatDateTime, tr } from "utils/globalsUtils";
import { on } from "events";
import { addAlert, useMaterialUIController } from "context";

const MDTypography = require("components/MDTypography").default;
const MDInput = require("components/MDInput").default;
const MDButton = require("components/MDButton").default;
const MDBox = require("components/MDBox").default;

function logEntry(log, index, shRequest, mms?, isAdmin?) {
  let text: ReactElement;
  let sidesResponses = "";
  let color = "grey";
  switch (log.type) {
    case "comment":
      text = log.content.comment;
      break;
    case "statusChange":
      text = <T>statusChangeLog{log.content.status}</T>
      break;
    case "declineIntroduction":
      text = <T params={{ reason: tr(log.content.declineReason || "") }}>declineIntroductionSh</T>;
      break;
    case "changeRequest":
      const user =
        log.content.profileId === shRequest.initiatorProfileId
          ? shRequest.initiatorUser
          : shRequest.responderUser;
      text = <T params={{ action: log.content.action, user: user.firstName }}>changeRequestLog</T>;
      color = "red";
      break;
    case "dateLog":
      text = (
        <span>
          <T params={{ dateNumber: log.content.dateNumber }}>dateLog</T> - {log.content.dateComment}
        </span>
      );
      break;
    default:
      text = <T>{log.type}Log</T>;
  }

  if (log.content?.byAdmin) {
    color = "red";
  }


  if (log.content?.initiatorStatus) {
    sidesResponses += " - " + shRequest.initiatorUser.firstName + " " + camelCaseToCapitalizedWords(log.content.initiatorStatus);
  }
  if (log.content?.responderStatus) {
    sidesResponses += " - " + shRequest.responderUser.firstName + " " + camelCaseToCapitalizedWords(log.content.responderStatus)
  }

  return (
    <div
      key={index}
      style={{
        fontSize: "12px",
        color,
      }}
    >
      {formatDateTime(log.created)}: {text} {sidesResponses}{" "}
      {log.content?.byAdmin && <T>byAdmin</T>}
      {isAdmin && (" - " + mms?.find(mm => mm.id == log.mm_id)?.user?.first_name)}
    </div>
  );
}

export const MMLogs = ({ shRequest,isAdmin }: { shRequest: Introduction, isAdmin?: boolean }) => {
  const [mms, setMms] = useState([]);

  useEffect(() => {
    getMMListByIDs(shRequest.mmLogs.map((log) => log.mm_id)).then((data) => setMms(data));
  }, []);
  shRequest.mmLogs?.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
  return (
    <Grid>
      {shRequest.mmLogs
        ?.filter((log) => log.type !== "comment")
        .map((log, index) => logEntry(log, index, shRequest, mms,isAdmin))}
    </Grid>
  );
};

export const MMComments = ({ shRequest }: { shRequest: Introduction }) => {
  const [controller, dispatch] = useMaterialUIController();

  async function addComment(introductionId, comment) {
    if (!comment || loading) return;
    setLoading(true);
    try {
      await addMMComment(introductionId, comment);
      shRequest.mmLogs.push({ type: "comment", created: new Date(), content: { comment } });
      setComment("");
    } catch (e) {
      addAlert(dispatch, "commentAddFailed", true );
    }
    setLoading(false);
  }

  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  shRequest.mmLogs?.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
  return (
    <Grid>
      <MDBox sx={{ mb: 2 }}>
        {shRequest.mmLogs?.filter((log) => log.type === "comment").map(logEntry)}
      </MDBox>
      <MDInput
        fullWidth
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            addComment(shRequest.introductionId, comment);
            e.preventDefault();
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton color="secondary" aria-label="add an alarm">
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <SendIcon onClick={() => addComment(shRequest.introductionId, comment)} />
              )}
            </IconButton>
          ),
        }}
      ></MDInput>
    </Grid>
  );
};
