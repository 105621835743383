import { Grid, Switch } from "@mui/material";
import { VerticalDivider } from "components/alove/VerticalDivider";
import { useMaterialUIController } from "context";
const MDTypography = require("components/MDTypography/index.js").default;

export type NotifcationSetting = {
  Email: boolean;
  WA: boolean;
  SMS: boolean;
}

export const SUNotificationsSettings = ({notification, setNotification}:{notification: NotifcationSetting, setNotification: (notification: NotifcationSetting) => void}) => {
  const [controller] = useMaterialUIController();
  const { mobileView } = controller;
  return (
    <Grid container alignItems="center" style={{width:mobileView ? "70%" : "100%"}} justifyContent={"space-between"}>
      <Switch checked={notification.Email} onChange={() => setNotification({...notification, Email: !notification.Email})} />
      <MDTypography variant="body2">Email Notification</MDTypography>
      <VerticalDivider />
      <Switch checked={notification.WA} onChange={() => setNotification({...notification, WA: !notification.WA})} />
      <MDTypography variant="body2">WhatsApp Notification</MDTypography>
      <VerticalDivider />
      <Switch checked={notification.SMS} onChange={() => setNotification({...notification, SMS: !notification.SMS})} />
      <MDTypography variant="body2">SMS Notification</MDTypography>
    </Grid>
  );
};
