import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { Chip, Divider, Icon, List } from "@mui/material";

import { GridColDef } from "@mui/x-data-grid";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { getUsersStatsDO, getSystemUsersDO } from "utils/systemUsersUtils";
import {
  getByEmailPhone,
  getPendingProfiles,
  getEndusersList,
  getProfileStats,
} from "utils/enduserUtils";
import { EndUser } from "models/profile/EndUser";
import { AppFeature, hasFeature } from "utils/settingsUtils";
import { StatsPanel } from "components/alove/StatsPanel";
import { TwoSizeChip } from "components/alove/Chips";
import { PermissionType } from "models/BO/BoRole";
import { brandLogo, getBrandsDO } from "utils/brandsUtils";
import { Brand } from "models/BO/Brand";
import { Profile, ProfileStatus } from "models/profile/Profile";
import { formatDateTime, formatDate } from "utils/globalsUtils";
const { useMaterialUIController } = require("context");
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDInput = require("components/MDInput/index.js").default;
const MDAvatar = require("components/MDAvatar/index.js").default;

function ViewEndUsersList() {
  const pageNumber = "2.1";
  const [controller, dispatch] = useMaterialUIController();

  const { currentBrand, currentHost, currentRole } = controller;
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    totalProfiles: 0,
    activeProfiles: 0,
    userPerMonth: 0,
    deactivatedProfiles: 0,
  });
  const [showPending, setShowPending] = useState(false);
  const [brands, setBrands] = useState([] as Brand[]);

  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.ViewAllOptions);
  const columns: TableColumnProps<EndUser>[] = [
    {
      headerName: "Brand",
      field: "brand_id",
      width: 70,
      align: "center",
      hidden: () => !currentBrand.isAlove,
      renderCell: (params) => renderBrand(params),
    },
    { headerName: "First Name", field: "firstName", flex: 1, minWidth: 100 },
    { headerName: "Email", field: "email", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phoneNumber", flex: 1, minWidth: 150 },
    {
      headerName: "Created",
      field: "created",
      flex: 1,
      minWidth: 170,
      align: "center",
      renderCell: (params) => formatDateTime(params.row.created),
    },
    {
      headerName: "Last Login",
      field: "lastLogin",
      flex: 1,
      minWidth: 170,
      align: "center",
      renderCell: (params) => formatDateTime(params.row.lastLogin),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params) => {
        const user = Object.assign(new Profile(), params.row);
        return <TwoSizeChip label={user.statusLabel} color={user.statusColor as any} />;
      },
    },
    {
      headerName: "actions",
      flex: 1,
      field: "actions",

      align: "center",
      renderCell: (params) => (
        <Icon onClick={() => navigate(`/systemUsers/systemUser?id=${params.id}`)}>edit</Icon>
      ),
    },
  ];
  useEffect(() => {
    if (!currentBrand?.id) return;
    getProfileStats().then((res) => {
      setStats(res);
    });
    // if (hasFeature(AppFeature.pendingProfiles, currentHost, currentBrand.id)){
    //   setShowPending(true);
    // }
     

    getBrandsDO(currentHost).then((res) => {
      setBrands(res || []);
    });
  }, [currentBrand ,currentHost]);

  const renderBrand = (params) => {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
      <MDAvatar
        src={brandLogo(brands?.find((b) => b.id == params.row.brand_id))}
        bgColor="light"
        size="sm"
      />
      </Grid>
    );
  };

  const getEndUser = async (host: string, filters: any, page: number) => {
    if (filters["search"]) {
      return getByEmailPhone( filters["brand"] || 100, { ...filters });
    }
    console.log("filters", filters);
    if (isAdmin) {
      return getEndusersList( filters["brand"] || 100, filters["status"] || [], filters["showInternal"], page);
    }
    return [];
  };
  const getPending = async (host: string, filters: any, page: number) => {
    return getPendingProfiles(currentBrand.id);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="End Users" />
      <Grid container>
        <StatsPanel
          title="End Users Statistics"
          fields={[
            {
              title: "Total Users",
              name: "totalProfiles",
            },
            {
              title: "Active Users",
              name: "activeProfiles",
              color: "primary",
            },
            {
              title: "User per Month",
              name: "userPerMonth",
            },
            {
              title: "Suspened Users",
              name: "deactivatedProfiles",
              color: "error",
            },
          ]}
          stats={stats}
        />
        {showPending && (
          <TablePage
            title="Pending"
            table={{
              columns,
              loadNext: getPending,
              lineClick: (row) => navigate(`${row.id}`),
            }}
          />
        )}
        <TablePage
          title={isAdmin ? "End Users List" : "Find User By"}
          table={{
            columns,
            loadNext: getEndUser,
            lineClick: (row) => navigate(`${row.id}`),
            pagination: isAdmin,
            rowCount: Number(stats.totalProfiles)
          }}
          filters={[
            {
              label: "search",
              placeholder: "By phone, email, ID",
              type: "search",
            },
            {
              hidden: !isAdmin,
              label: "status",
              multiple: true,
              options: Object.keys(ProfileStatus).filter((key) => !isNaN(Number(key))).map((key) => ({
                key: key,
                label: ProfileStatus[key],
              })),
            },

            {
              hidden: !currentBrand.isAlove,
              label: "brand",
              options: brands?.map((brand) => ({ key: brand.id, label: brand.name })),
            },
            {
              label: "showInternal",
              type: "switch",
            }
          ]}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default ViewEndUsersList;
