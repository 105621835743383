import { Autocomplete, Grid } from "@mui/material";
import { UserAgent } from "models/profile/UserAgent";

const MDInput = require("components/MDInput").default;

export const DeviceInfo = ({ agent, setAgent }: { agent: UserAgent; setAgent: any }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MDInput
          fullWidth
          label="Build"
          value={agent.build}
          type="number"
          onChange={(e: any) => {
            setAgent({ ...agent, build: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={6}/>
      <Grid item xs={6}>
        <MDInput
          fullWidth
          label="Manufacturer"
          value={agent.manufacturer}
          onChange={(e: any) => {
            setAgent({ ...agent, manufacturer: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <MDInput
          fullWidth
          label="Model"
          value={agent.model}
          onChange={(e: any) => {
            setAgent({ ...agent, model: e.target.value });
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          fullWidth
        options={["Android", "iOS", "WEB"]}
            renderInput={(params) => <MDInput {...params} label="System" />}
          value={agent.system || ""}
          onChange={(e: any, o ) => {
            setAgent({ ...agent, system: o });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <MDInput
          fullWidth
          label="OS Version"
          value={agent.OSVersion}
          onChange={(e: any) => {
            setAgent({ ...agent, OSVersion: e.target.value });
          }}
        />
      </Grid>
    </Grid>
  );
};
