import {
  InstructionItem,
  MenuScreenItem,
  ScreenCard,
  ScreenModel,
  ScreenType,
} from "models/content/Screen";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Collapse, Divider, Grid, ListItemIcon } from "@mui/material";
import iphone from "assets/images/alove/iphone.png";
import { BrandLogo } from "components/alove/BrandLogo";
import { Setting } from "models/BO/Setting";
import MenuIcon from "@mui/icons-material/Menu";
import { Approval, ArrowBackIos, ArrowForwardIos, Cancel, CheckCircle, Clear, Grid4x4TwoTone, OneK } from "@mui/icons-material";
import { defaultAvatarUrl, groupBy } from "utils/globalsUtils";

const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export const ScreenPreview = ({ screen, colors }: { screen: ScreenModel; colors: Setting[] }) => {
  const [openedCard, setOpenedCard] = React.useState<number | null>(null);

  const getColor = (colorName?: string) => {
    if (colorName == "black") {
      return "#000000";
    }
    const value = colors
      .find((color) => color.paramName === colorName)
      ?.paramValue?.replace("0xFF", "");
    return value ? "#" + value : "";
  };

  const fileds = {
    image: screen.image ? (
      <img
        style={{
          height: (screen.styles?.iconSize || 100) + "px",
        }}
        src={screen.image}
      />
    ) : screen.styles?.showAppIcon ? (
      <BrandLogo size={(screen.styles?.iconSize || 100) + "px"} />
    ) : screen.styles?.showPartners ? (
      <Grid container justifyContent="center">
        <img
          style={{
            height: (screen.styles?.iconSize || 100) + "px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          src={defaultAvatarUrl("m")}
        />
        <img
          style={{
            height: (screen.styles?.iconSize || 100) + "px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          src={defaultAvatarUrl("w")}
        />
      </Grid>
    ) : null,
    title: screen.title && (
      <MDTypography align="center" fontSize={(screen.styles?.titleSize || 20) + "px"}>
        {screen.title}
      </MDTypography>
    ),
    subtitle: screen.subtitle && (
      <MDTypography align="center" fontSize={(screen.styles?.subtitleSize || 16) + "px"}>
        {screen.subtitle}
      </MDTypography>
    ),

    body:
      screen.type === ScreenType.SliderScreen ? (
        <div style={{ width: "100%", overflow: "scroll" }}>
          <Grid
            container
            flexDirection="row"
            style={{
              display: "flex",
              flexDirection: "row",
              width: screen.slides.length * 300,
            }}
          >
            {screen.slides.map((text: String, index: number) => (
              <div style={{ width: "280px", padding: "10px" }} key={index}>
                <MDTypography key={index} variant="body2" align="center">
                  {text}
                </MDTypography>
              </div>
            ))}
          </Grid>
        </div>
      ) : (
        screen.body && (
          <MDTypography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {screen.body}
          </MDTypography>
        )
      ),
    button1Title: screen.button1Title && (
      <MDButton
        variant="contained"
        bgColor={getColor(screen.styles?.button1Color)}
        color="primary"
        fullWidth
        circular
      >
        {screen.button1Title}
      </MDButton>
    ),
    button2Title: screen.button2Title && (
      <MDButton
        variant="contained"
        color="primary"
        fullWidth
        circular
        bgColor={getColor(screen.styles?.button2Color)}
      >
        {screen.button2Title}
      </MDButton>
    ),
    cancelTitle: screen.cancelTitle && (
      <MDButton
        variant={getColor(screen.styles?.cacnelColor) ? "outlined" : "text"}
        color="text"
        fullWidth
        circular
        bgColor={getColor(screen.styles?.cacnelColor)}
      >
        {screen.cancelTitle}
      </MDButton>
    ),
    note: screen.note && (
      <MDTypography variant="body2" align="center">
        {screen.note}
      </MDTypography>
    ),
  };

  const cardsScreen = () => {
    return (
      <Grid p={2} container style={{ overflow: "scroll", height: "100%" }}>
        {screen.cards?.map((card: ScreenCard, index) => {
          return (
            <Grid
              key={index}
              container
              onClick={() => setOpenedCard(openedCard === index ? null : index)}
              style={{ cursor: "pointer" }}
              flexDirection="column"
            >
              <MDTypography variant="h5">{card.title}</MDTypography>
              <Collapse in={openedCard === index}>
                <MDTypography variant="body1">{card.body}</MDTypography>
                {card.image && (
                  <img
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                    src={card.image}
                  />
                )}
              </Collapse>
              <Divider />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const menuScreen = () => {
    return (
      <Grid p={2} container style={{ overflow: "scroll", height: "100%" }}>
        <MDTypography variant="h3" align="center" my={1}>
          {screen.title}
        </MDTypography>
        {Object.entries(groupBy(screen.menuItems, (m) => m.panel)).map(([panel, group]) => {
          return (
            <Grid container justifyContent="space-between">
              <MDTypography variant="h5" color="primary" my={1}>
                {panel}
              </MDTypography>
              {(group as []).map((card: MenuScreenItem, index) => {
                return (
                  <Grid flexDirection="row" container justifyContent="space-between" key={index}>
                    <Grid
                      key={index}
                      container
                      style={{ cursor: "pointer", opacity: !card.nextEventType ? 0.5 : 1 }}
                      flexDirection="column"
                      item
                      xs={9}
                    >
                      <MDTypography variant="h5">{card.title}</MDTypography>
                      <MDTypography variant="body1">{card.subtitle}</MDTypography>

                      <Divider />
                    </Grid>
                    <Grid item xs={2} container justifyContent="center" alignItems="center">
                      <ArrowForwardIos />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
        <MDButton
          variant="text"
          bgColor={getColor(screen.styles?.button1Color)}
          color="primary"
          fullWidth
          circular
        >
          {screen.button1Title}
        </MDButton>
        <MDButton
          variant="text"
          color="primary"
          fullWidth
          circular
          bgColor={getColor(screen.styles?.button2Color)}
        >
          {screen.button2Title}
        </MDButton>
      </Grid>
    );
  };

  const contentScreen = () => (
    <Grid
      container
      gap={2}
      p={1}
      bgcolor="lightgray"
      style={{ overflow: "scroll", height: "100%" }}
    >
      <Grid item container alignItems="center">
        <Grid item xs={2} container justifyContent="center" alignItems="center">
          <ArrowBackIos />
        </Grid>
        <MDTypography variant="h3">{screen.title}</MDTypography>
      </Grid>
      <MDTypography variant="h5">{screen.subtitle}</MDTypography>
      <Grid item container bgcolor="#FFFFFF" p={1} style={{ borderRadius: "10px" }}>
        <MDTypography fontSize="12px">{screen.body}</MDTypography>
      </Grid>
    </Grid>
  );

  const instructionItem = (instruction: InstructionItem) => (
    <Grid
      container
      key={instruction.title}
      p={2}
      gap={2}
      justifyContent="start"
      style={{ width: "300px" }}
    >
      <Grid item xs={12} container justifyContent="center">
        <MDTypography>{instruction.title}</MDTypography>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <MDTypography variant="body2">{instruction.body}</MDTypography>
      </Grid>
      <Grid item xs={5} container justifyContent="center">
        <img src={instruction.badImage} style={{ width: "100%", height:"200px", objectFit: "cover" }} />
        <Cancel />
      </Grid>
      <Grid item xs={5} container justifyContent="center">
        <img src={instruction.goodImage} style={{ width: "100%", height:"200px", objectFit: "cover" }} />
        <CheckCircle />
      </Grid>
    </Grid>
  );

  const getLayout = (screenType: ScreenType) => {
    switch (screenType) {
      case ScreenType.Popup:
        return (
          <Grid
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "gray",
            }}
            container
            alignContent="center"
            justifyContent="center"
          >
            <Grid
              container
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              mx={2}
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                padding: "20px",
              }}
            >
              {screen.fieldsOrder?.map((field) => (
                <React.Fragment key={field}> {fileds[field]}</React.Fragment>
              ))}
            </Grid>
          </Grid>
        );
      case ScreenType.InstructionScreen:
        return (
          <Grid
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "gray",
            }}
            container
            alignContent="end"
          >
            <Grid
              style={{
                width: "100%",
                height: "80%",
                backgroundColor: "white",
                borderRadius: "20px 20px 0 0",
                padding: "20px",
                overflow: "scroll",
              }}
            >
              <MDTypography variant="h3" align="center">
                {screen.title}
              </MDTypography>
              <Grid
                container
                alignItems="center"
                flexDirection={"row"}
                gap={2}
                justifyContent="space-evenly"
                wrap="nowrap"
                style={{
                  width: (screen.instructionItems.length * 300) + "px",
                  overflow: "scroll",
                }}
              >
                {screen.instructionItems?.map((field) => instructionItem(field))}
              </Grid>
            </Grid>
          </Grid>
        );
      case ScreenType.Curtain:
        return (
          <Grid
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "gray",
            }}
            container
            alignContent="end"
          >
            <Grid
              container
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              justifyContent="space-evenly"
              wrap="nowrap"
              style={{
                height: "80%",
                width: "100%",
                backgroundColor: "white",
                borderRadius: "20px 20px 0 0",
                padding: "20px",
                overflow: "scroll",
              }}
            >
              {screen.fieldsOrder?.map((field) => (
                <React.Fragment key={field}> {fileds[field]}</React.Fragment>
              ))}
            </Grid>
          </Grid>
        );

      case ScreenType.FullScreen:
      case ScreenType.SliderScreen:
        return (
          <Grid
            justifyContent="space-evenly"
            alignItems="center"
            flexDirection={"column"}
            wrap="nowrap"
            container
            style={{
              height: "100%",
              width: "100%",
              overflow: "scroll",
            }}
            px={1}
          >
            {screen.fieldsOrder?.map((field) => (
              <React.Fragment key={field}> {fileds[field]}</React.Fragment>
            ))}
          </Grid>
        );
      case ScreenType.CardsScreen:
        return cardsScreen();
      case ScreenType.MenuScreen:
        return menuScreen();
      case ScreenType.ContentScreen:
        return contentScreen();
      default:
        return "Error";
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${iphone})`,
        backgroundSize: "cover",
        width: "349px",
        height: "700px",
        padding: "80px 20px 80px 22px",
      }}
    >
      {getLayout(screen.type)}
    </div>
  );
};
