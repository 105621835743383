import { Grid } from "@mui/material";
import { VerticalDivider } from "components/alove/VerticalDivider";
import {
  HeaderIndication,
  HeaderIndicationProps,
} from "layouts/itemPageContainer/components/headerIndication";
import React, { useEffect } from "react";
import { getCustomerSupportTopicsDO } from "utils/customerSupportUtils";
import { getTickets } from "utils/enduserUtils";
import { networkGlobals } from "utils/networkUtils";

export const CSTickets = ({ profileId }) => {
  const [tickets, setTickets] = React.useState([] as HeaderIndicationProps[]);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (!profileId) return;
    Promise.all([getCustomerSupportTopicsDO(), getTickets(profileId)]).then(([topics, res]) => {
      const tickets = (topics as any[]).map((topic) => ({ title: topic.name, value: res[topic.key] || 0 }));
      setTickets([{ title: "open", value: res.open || 0 }, ...tickets]);
    })
  }, [profileId]);

  return (
    <Grid container display="flex" justifyContent="start" gap={1} flexDirection="row">
      {tickets
        .map((indication: HeaderIndicationProps, index) => [
          <HeaderIndication {...indication} index={index} key={indication.title} />,
          index != tickets.length - 1 && <VerticalDivider height="40px" key={index} />,
        ])
        .flat()}
    </Grid>
  );
};
