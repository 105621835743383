import { Clear } from "@mui/icons-material";
import { Grid, IconButton, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { uploadImage } from "utils/networkUtils";

const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "100%",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: "100%",
});

let urls: string[] = [];
export const ImageUploader = ({
  initImage,
  onUploaded,
  count = 1,
  folder,
  buttonOnly,
}: {
  initImage?: string[];
  onUploaded: (images: string[]) => void;
  count?: number;
  folder: string;
  buttonOnly?: boolean;
}) => {
  const [images, setImages] = useState(initImage || []);

  useEffect(() => {
    setImages(initImage || []);
    urls = [];
  }, [initImage]);

  const pickImage = async (e) => {
    if (!e.target.files) return;
    const previews: string[] = [];
    Promise.all(
      (Array.from(e.target.files) as File[]).slice(0, count).map(
        (file) =>
          new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              previews.push(reader.result as string);
              uploadImage(file, folder).then((res) => resolve(res.Location));
            };
            reader.readAsDataURL(file);
          })
      )
    ).then((res: string[]) => {
      setImages(images.slice(0, count - previews.length).concat(previews));
      urls = urls.slice(0, count - previews.length).concat(res);
      onUploaded(urls);
    });
  };

  return buttonOnly ? (
    <MDButton component="label" variant="outlined" color="primary">
      <VisuallyHiddenInput type="file" onChange={pickImage} accept="image/*" multiple={count > 1} />
      Browse
    </MDButton>
  ) : (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      style={{
        border: "1px solid black",
        borderRadius: "5px",
        padding: "5px 15px",
      }}
    >
      {!!images.length ? (
        images.map((image) => (
          <img
            style={{
              width: "30%",
              height: "100px",
              objectFit: "cover",
            }}
            src={image}
            alt="screen"
          />
        ))
      ) : (
        <MDTypography variant="body2">
          Upload Image{count > 1 ? `s (up to ${count})` : ""}
        </MDTypography>
      )}

      {!!images.length && (
        <IconButton
          onClick={() => {
            setImages([]);
            onUploaded([]);
          }}
        >
          <Clear />
        </IconButton>
      )}
      <MDButton component="label" variant="outlined" color="primary">
        <VisuallyHiddenInput
          type="file"
          onChange={pickImage}
          accept="image/*"
          multiple={count > 1}
        />
        Browse
      </MDButton>
    </Grid>
  );
};
