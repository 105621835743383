import { Divider } from "@mui/material";
import { useMaterialUIController } from "context";

export const VerticalDivider = ({ hidden, height }: { hidden?: boolean; height?: string }) => {

  const [controller] = useMaterialUIController();
  const { mobileView } = controller;
  return mobileView ? null : (
    
    <Divider
      orientation="vertical"
      sx={{m:1}}
      style={{
        height: height || "32px",
        color: "black",
        borderStyle: "dotted",
        borderWidth: "2px",
        visibility: hidden ? "hidden" : "visible",
      }}
    />
  );
};
