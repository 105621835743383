import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode";
import { getGeneralCodes } from "./generalCodesUtils";
import { makeRequest } from "./networkUtils";
import { Ticket, TicketSource } from "models/customer-supports/Ticket";
import { CSSetting } from "models/customer-supports/CSSetting";

/** Get list of customer support topics */
export const getCustomerSupportTopicsDO = async (): Promise<GeneralCode[]> => {
  return getGeneralCodes([GeneralCodeType.TicketTopic, GeneralCodeType.SystemTicketTopic]);
};

/** Get list of customer support tickets */
export const getTicketsDO = async (filters, page, source: TicketSource): Promise<Ticket[]> => {
  return makeRequest(`customerSupport?filters=${filters}&page=${page}&source=${source}`).then(
    (data) => data.map((ticket) => Object.assign(new Ticket(), ticket))
  );
};

/** Get a single ticket */
export const getTicketDO = async (ticketID): Promise<Ticket> => {
  return makeRequest(`customerSupport/${ticketID}`).then((ticket) =>
    Object.assign(new Ticket(), ticket)
  );
};

/** Get ticket responses */
export const getTicketResponses = (ticketId: string) => {
  return makeRequest(`customerSupport/${ticketId}/responses`);
};

/** Add a response to a ticket */
export const addResponse = async (content: string, recipient: string, ticketId: string) => {
  return makeRequest("customerSupport/responses", {
    method: "POST",
    body: { content, recipient, ticketId },
  });
};

/** Update ticket assignee */
export const updateAssigneeDO = async (assignee, id) => {
  return makeRequest(`customerSupport/${id}/assignee`, { method: "PUT", body: { assignee, id } });
};

/** Update ticket status */
export const updateTicketStatusDO = async (data, id) => {
  return makeRequest(`customerSupport/${id}/status`, { method: "PUT", body: data });
};


export const updateTicket = async (data, id) => {
  return makeRequest(`customerSupport/${id}`, { method: "PUT", body: data });
};

export const deleteTicketDO = async (id) => {
  return makeRequest(`customerSupport/${id}`, { method: "DELETE" });
}


/** Get SLA settings */
export const getSLASettingsDO = async (): Promise<CSSetting[]> => {
  return makeRequest("customerSupport/settings");
};

export const updateCSSetting = async (data, id) => {
  return makeRequest(`customerSupport/settings/${id}`, { method: "PUT", body: data });
};

export const createTicketDO = async (data) => {
  return makeRequest("customerSupport", { method: "POST", body: data });
};

export const getTicketLogs = async (ticketId: string) => {
  return makeRequest(`customerSupport/${ticketId}/logs`);
};

export const insertTicketLog = async (ticketId: string, log: string) => {
  return makeRequest(`customerSupport/${ticketId}/logs`, { method: "POST", body: { log } });
};
