import { Grid, Autocomplete } from "@mui/material";
import { ImageUploader } from "components/alove/ImageUploader";
import { BoUser } from "models/BO/BoUser";
import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode";
import { CSSetting } from "models/customer-supports/CSSetting";
import { TicketPriority } from "models/customer-supports/Ticket";
import { useState, useEffect } from "react";
import { getSLASettingsDO } from "utils/customerSupportUtils";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { numbers, camelCaseToCapitalizedWords } from "utils/globalsUtils";
import { getSystemUsersDO } from "utils/systemUsersUtils";

const MDInput = require("components/MDInput").default;

export const TicketInfo = ({ ticket, setTicket }) => {
  const [topics, setTopics] = useState<GeneralCode[]>([]);
  const [subTopics, setSubTopics] = useState<GeneralCode[]>([]);
  const [brandUsers, setBrandUsers] = useState([] as BoUser[]);
  const [slaSettings, setSLASettings] = useState([] as CSSetting[]);

  useEffect(() => {
    getGeneralCodes(GeneralCodeType.SystemTicketTopic).then((data) => {
      setTopics(data);
    });
    getGeneralCodes(GeneralCodeType.SystemTicketSubTopic).then((data) => {
      setSubTopics(data);
    });

    getSystemUsersDO().then((users) => {
      setBrandUsers(users);
    });

    getSLASettingsDO().then((data) => {
      setSLASettings(data);
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={6}>
        <Autocomplete
          options={numbers(TicketPriority)}
          defaultValue={TicketPriority.Default}
          getOptionLabel={(option) =>
            option + " - " + camelCaseToCapitalizedWords(TicketPriority[option])
          }
          renderInput={(params) => <MDInput {...params} label="Priority" />}
          onChange={(e, value) => {
            setTicket((prev: any) => ({ ...prev, priority: value }));
          }}
        />
      </Grid>
      <Grid item md={8} />
      <Grid item md={4} xs={6}>
        <Autocomplete
          options={topics}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <MDInput {...params} label="Topic" />}
          onChange={(e, value) => {
            const assignee = slaSettings.find((setting) => setting.topicId === value?.id)?.assignee;
            setTicket((prev: any) => ({ ...prev, topicId: value?.id, assignedTo: assignee }));
          }}
        />
      </Grid>

      <Grid item md={4} xs={6}>
        <Autocomplete
          options={subTopics}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <MDInput {...params} label="Sub Topic" />}
          onChange={(e, value) => {
            setTicket((prev: any) => ({ ...prev, subTopicId: value?.id }));
          }}
        />
      </Grid>

      <Grid item md={8} xs={12}>
        <Autocomplete
          options={brandUsers}
          multiple
          getOptionLabel={(option) => option.fullName}
          renderInput={(params) => <MDInput {...params} label="Assigned To" />}
          value={brandUsers.filter((user) => ticket?.assignedTo?.includes(user.id)) || []}
          onChange={(e, value) => {
            setTicket((prev: any) => ({ ...prev, assignedTo: value.map((user) => user.id) }));
          }}
        />
      </Grid>

      <Grid item md={8} xs={12}>
        <MDInput
          fullWidth
          label="Content"
          multiline
          rows={10}
          onChange={(e) => {
            setTicket((prev: any) => ({ ...prev, content: e.target.value }));
          }}
        />
      </Grid>

      <Grid item md={8} xs={12}>
        <ImageUploader
          count={3}
          folder="ticketScreenshots"
          onUploaded={(e) => {
            setTicket((prev: any) => ({ ...prev, screenshot: e.join(",") }));
          }}
        />
      </Grid>
    </Grid>
  );
};
