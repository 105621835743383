import { UserAgent } from "models/profile/UserAgent";
import { TicketSubTopic } from "./TicketSubTopic";
import { TicketTopic } from "./TicketTopic";
import { GeneralCode } from "models/content/GeneralCode";
import { Statusable } from "models/BO/BoUser";

export enum TicketStatus {
  New = 1,
  Open = 2,
  OnHold = 3,
  Solved = 4,
  Closed = 5,
  Canceled = 6,
}

export enum TicketPriority {
  ShowStopper = 1,
  High = 2,
  Default = 3,
  Low = 4,
  Feedback = 5,
}



export enum TicketSource {
	MobileApp = 0,
	AdminPanel = 1,
}	


export class 
Ticket implements Statusable {
  id: number;

  assignedTo: any;

  created: Date;

  status: TicketStatus;

  SLA: number;

  brandId: number;

  lastUpdate: Date;

  profileId: string;

  screenshot: string;

  content: string;

  userAgent: UserAgent;

  topicId: number;
  topic: GeneralCode;

  subTopic: TicketSubTopic;

  reportee: string;
  slaFirst: number;

  reporter: string;

  priority: TicketPriority;

  source: TicketSource;

  internal: boolean;

  get statusLabel(): string {
    return TicketStatus[this.status || 1];
  }

  get statusColor(): string {
    switch (Number(this.status)) {
      case TicketStatus.Solved:
        return "success";
      case TicketStatus.New:
        return "warning";
      case TicketStatus.Closed:
        return "error";
      default:
        return "info";
    }
  }

  get statuses(): { key: string; value: string }[] {
    return Object.keys(TicketStatus)
      .filter((key) => !isNaN(Number(key)))

      .map((key) => ({
        key,
        value: TicketStatus[key],
      }));
  }

  get statusReason(): string {
    return "";
  }
}
