import { Grid } from "@mui/material";
import { T } from "components/alove/Translator";
const MDTypography = require("components/MDTypography/index.js").default;
import Tooltip from "@mui/material/Tooltip";
import { useMaterialUIController } from "context";

export interface HeaderIndicationProps {
  title: string | number;
  value: string | number;
  hidden?: boolean;
  index?: number;
  color?: string | undefined;
  onClick?: () => void;
  fixedSize?: number;
  tooltip?: string;
  columns?: number;
}

export const HeaderIndication = (props: HeaderIndicationProps) => {
  const { title, value, index, color, onClick, fixedSize,tooltip, columns } = props;
  const [controller] = useMaterialUIController ();
  const { mobileView } = controller;

  return (
    <Tooltip title={tooltip || <T>{value}</T>}> 
    <Grid
      item
      onClick={onClick}
      style={ { cursor: onClick ? "pointer" : "default",textOverflow: "ellipsis", overflow: "hidden"  }
      }
      sx={{ width: fixedSize ? fixedSize : null }}
      container
      xs={mobileView ? (columns || 3) : "auto"}
      sm={mobileView ? (columns || 2) : "auto"}
      direction="column"
      justifyContent="end"
    >
      <MDTypography variant="body2" style={{ textWrap: "nowrap", textTransform: "capitalize",}}>
        <T>{title}</T>
      </MDTypography>
      {fixedSize ? (
        <Tooltip key={index} title={<T>{value}</T>}>
          <MDTypography
            variant="h5"
            color={color}
            style={{ textWrap: "nowrap" }}
          >
            <T>{value?.toString()?.slice(0, 25)}</T>
          </MDTypography>
        </Tooltip>
      ) : (
        <MDTypography
          variant="h5"
          color={color || (onClick ? "primary" : "inherit")}
          style={{ textWrap: "nowrap", textDecoration: onClick ? "underline" : "none" }}
        >
          <T>{value}</T>
        </MDTypography>
      )}
    </Grid>
    </Tooltip>
  );
};
