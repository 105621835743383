import { Grid } from "@mui/material";
import { ImageDialog } from "components/alove/ImageDialog";
import { Ticket } from "models/customer-supports/Ticket";
import { formatDateTime } from "utils/globalsUtils";

const MDTypography = require("components/MDTypography/index.js").default;

export const TicketContent = ({ ticket }: { ticket: Ticket }) => {
  return (
    <Grid container>
      <Grid item md={12}>
        <MDTypography variant="body2">
          On {formatDateTime(ticket?.created)} user has written:
        </MDTypography>
      </Grid>
      <Grid item md={6} sx={{ display: "flex", whiteSpace: "pre-wrap" }}>
        <MDTypography variant="body2">{ticket?.content}</MDTypography>
      </Grid>
      <Grid item md={6} container spacing={3}>
        {ticket?.screenshot?.split(",").map((image, index) => (
          <Grid item md={4} key={index}>

            <ImageDialog url={image} size="100px" />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
