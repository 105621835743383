import { Grid, Divider, TextField, IconButton, CircularProgress } from "@mui/material";
import logs from "layouts/logs";
import { PermissionType } from "models/BO/BoRole";
import { Ticket, TicketStatus } from "models/customer-supports/Ticket";
import { useEffect, useState } from "react";
import {
  addResponse,
  getTicketLogs,
  getTicketResponses,
  insertTicketLog,
} from "utils/customerSupportUtils";
import { Message } from "./message";
import { useMaterialUIController } from "context";
import SendIcon from "@mui/icons-material/Send";
import { TicketLog, TicketLogType } from "models/customer-supports/TicketLog";
import { formatDateOrTime } from "utils/globalsUtils";
import { BoUser } from "models/BO/BoUser";

const MDTypography = require("components/MDTypography").default;

export const ReportInnerConversation = ({ ticket, users }: { ticket: Ticket; users: BoUser[] }) => {
  const pageNumber = "6.3";
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;

  const [logs, setLogs] = useState([] as TicketLog[]);
  const [loader, setLoader] = useState(false);
  let [newLog, setNewLog] = useState("");

  useEffect(() => {
    if (users.length) {
      getTickeLogs();
    }
  }, [users]);

  const getTickeLogs = async () => {
    const data = await getTicketLogs(ticket.id!.toString());
    data.forEach((log) => {
      log.user = users.find((u) => u.id === log.userId);
    });
    setLogs(data);
  };

  const sendLog = async (e) => {
    if ((e?._reactName === "onClick" || e?.code === "Enter") && newLog.length > 0) {
      e.preventDefault();
      setLoader(true);
      await insertTicketLog(ticket.id!.toString(), newLog);
      setNewLog("");
      setLoader(false);
      getTickeLogs();
    }
  };

  const getNames = (id: number[]) => {
    return users
      .filter((u) => id.includes(u.id))
      .map((u) => u.fullName)
      .join(", ");
  };

  const getLogContent = (log: TicketLog) => {
    switch (log.type) {
      case TicketLogType.message:
        return (
          <MDTypography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
            {log.log}
          </MDTypography>
        );
      case TicketLogType.statusChange:
        return (
          <MDTypography variant="body2" fontWeight="bold" color="primary">
            Status changed to: {TicketStatus[log.log]}
          </MDTypography>
        );
      case TicketLogType.assigned:
        return (
          <MDTypography variant="body2" fontWeight="bold" color="secondary">
            Assigned to: {getNames(JSON.parse(log.log))}
          </MDTypography>
        );
    }
  };

  return (
    <Grid container flexDirection="row" alignItems="flex-start" gap={1}>
      <Grid item xs={12} lg={12}>
        {logs
          .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
          .map((l, i) => (
            <Grid container key={i}>
              <Grid xs={2} item>
                <MDTypography variant="body2" fontWeight="light">
                  {formatDateOrTime(l.created)}
                </MDTypography>
              </Grid>

              <Grid xs={2} item>
                <a href={`#/systemUsers/${l.userId}`}>
                  <MDTypography variant="body2" fontWeight="bold">
                    {l.user?.fullName}
                  </MDTypography>
                </a>
              </Grid>
              <Grid xs={8} item>
                {getLogContent(l)}
              </Grid>
            </Grid>
          ))}
        <Divider />
      </Grid>
      {
        <Grid item xs={12}>
          <TextField
            id="standard-name"
            // label="Name"
            variant="outlined"
            style={{maxWidth: "500px"}}
            multiline
            rows={4}
            fullWidth
            placeholder="Add response"
            disabled={
              !currentRole.hasAccess(pageNumber, PermissionType.Edit) ||
              ticket?.status == TicketStatus.Closed
            }
            onChange={(e) => setNewLog(e.target.value)}
            value={newLog}
            onSubmit={(e) => sendLog(e)}
            InputProps={{
              endAdornment: (
                <IconButton color="secondary" aria-label="add an alarm">
                  {loader ? <CircularProgress size={20} /> : <SendIcon onClick={sendLog} />}
                </IconButton>
              ),
            }}
          />
        </Grid>
      }
    </Grid>
  );
};
