import { capitalize } from "@mui/material";
import { Statusable } from "models/BO/BoUser";
import { BaseContent } from "./Content";
import { IntroductionStatus } from "../introduction/Introduction";
import { camelCaseToCapitalizedWords, snakeCaseToCapitalizedWords } from "utils/globalsUtils";
import { NextScreenEvent } from "./Screen";


export enum EmailTemplate {
  EndUserIntroUpdate = "END_USER_INTRO_UPDATE",
  EndUserSystemA = "END_USER_SYSTEM_A",
  EndUserSystem = "END_USER_SYSTEM",
  Login = "LOGIN_VERIFICATION",
  MMIntroUpdate = "MM_INTRO_UPDATE",
  MMIntroUpdateA = "MM_INTRO_UPDATE_A",
  MMSystem = "MM_SYSTEM",
  MMSystemA = "MM_SYSTEM_A",
}

export const emailTemplateLabels = {
  [EmailTemplate.EndUserIntroUpdate]: ["HEADER1", "HEADER2", "NEXT_INSTRUCTION", "NEXT_ACTION"],
  [EmailTemplate.EndUserSystem]: ["HEADER1", "HEADER2", "NEXT_INSTRUCTION", "NEXT_ACTION"],
  [EmailTemplate.EndUserSystemA]: ["HEADER1", "HEADER2", "NEXT_INSTRUCTION", "NEXT_ACTION", "DECLINE_ACTION"],
  [EmailTemplate.Login]: ["HEADER1", "NEXT_INSTRUCTION"],
  [EmailTemplate.MMIntroUpdate]: ["HEADER1", "HEADER2", "NEXT_INSTRUCTION"],
  [EmailTemplate.MMIntroUpdateA]: [
    "HEADER1",
    "HEADER2",
    "NEXT_INSTRUCTION",
    "NEXT_ACTION",
    "DECLINE_ACTION",
  ],
  [EmailTemplate.MMSystem]: ["HEADER1", "HEADER2"],
  [EmailTemplate.MMSystemA]: ["HEADER1", "HEADER2", "NEXT_ACTION"],
};

export enum CommEvent {
  None = "None",
  IntroUpdate = "IntroUpdate",
  IntroUpdateInitiator = "IntroUpdateInitiator",
  IntroUpdateResponder = "IntroUpdateResponder",

  ChatEvent = "ChatEvent",
  CallEvent = "CallEvent",
  PartnerEvents = "PartnerEvents",

  LoraMessage = "LoraMessage",
  ItsQuietHere = "ItsQuietHere",

  MMEmails = "MMEmails",
  EndUserMMEmails = "EndUserMMEmails",

  Reminder = "Reminder",
}

export enum PushTemplate {
  None = "none",
  Popup = "popup",
  InnerMessage = "innerMessage",
  AppScreen = "appScreen",
}

export enum ItsQuietHereTypes {
	EmptyFirst = "EmptyFirst",
	EmptySecond = "EmptySecond",
	ShortFirst = "ShortFirst",
	ShortSecond = "ShortSecond",
	SingleSide = "SingleSide",
	MediumFirst = "MediumFirst",
	MediumSecond = "MediumSecond",
	LongFirst = "LongFirst",
	LongSecond = "LongSecond",
	PartnerObsessive = "PartnerObsessive",
	ContactsIncluded = "ContactsIncluded",
	ContactsIncluded2 = "ContactsIncluded2",
}

export enum ReminderType {
  introAvailabilityCheck = "introAvailabilityCheck",
  introAvailabilityCheckAnswered = "introAvailabilityCheckAnswered",
  introductionWaiting = "introductionWaiting",
  DNFReminder = "DNFReminder",
}

export class Comm  {
  id?: string;
  name: string;
  event?: CommEvent;
  param?: string[];
  email?: CommBody;
  push?: CommBody;
  sms?: CommBody;
  wa?: CommBody;
  categoryId: number;
}

export class CommBody extends BaseContent {
  title: string;
  body: string;
  template: string;
  popup: string;
  nextEventType: NextScreenEvent;
  actionUrl: string;
}

const getIntroStatuses = (): CommEventsParam[] => {
  return Object.values(IntroductionStatus)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      label: snakeCaseToCapitalizedWords(key as string),
      value: IntroductionStatus[key].toString()
    }))
    .concat([{
      label: "Intorduction Reminder",
      value: "REMIND_ABOUT_INTRODUCTION",
    },{
      label: "Intorduction Availability Reminder",
      value: "REMIND_ABOUT_AVAILABILITY_CHECK",
    }]);
};

const parse = (key: string): CommEventsParam => ({
  label: snakeCaseToCapitalizedWords(key),
  value: key,
});
const parseCamel = (key: string): CommEventsParam => ({
  label: camelCaseToCapitalizedWords(key),
  value: key,
});

export type CommEventsParam = {
  label: string;
  value: string;
};
export const CommEventsParams: { [key: string]: CommEventsParam[] } = {
  [CommEvent.IntroUpdate]: getIntroStatuses(),
  [CommEvent.IntroUpdateInitiator]: getIntroStatuses(),
  [CommEvent.IntroUpdateResponder]: getIntroStatuses(),
  [CommEvent.MMEmails]: Array.from({ length: 26 }, (_, i) => ({
    label: `MMEmail ${i}`,
    value: `EM${i}`,
  })),
  [CommEvent.EndUserMMEmails]: [
    {
      label: "No MM Assigned",
      value: "NO_MM",
    },
    {
      label: "MM Unavailable",
      value: "mmUnavailable",
    },
  ],
  [CommEvent.CallEvent]: [
    "MISSED_VOICE_CALL",
    "MISSED_VIDEO_CALL",
    "PARTNER_IS_CALLING",
    "activeCall",
  ].map(parse),
  [CommEvent.ChatEvent]: [
    "TEXT",
    "PHOTO",
    "VIDEO",
    "BREAK_THE_ICE",
    "BREAK_THE_ICE_RESPONSE",
    "DAILY_MOOD_REQUEST",
  ].map(parse),
  [CommEvent.LoraMessage]: [
    "newQuestionnaire",
    "dnfQuestions",
    "imageRejectedParam",
  ].map(parseCamel),
  [CommEvent.PartnerEvents]: [
    "PARTNER_BECAME_IRRELEVANT",
    "PARTNER_DELETED",
    "PARTNER_STARTED_SNOOZING",
    "PARTNER_STOPPED_SNOOZING",
  ].map(parse),
  [CommEvent.None]: [],
  [CommEvent.ItsQuietHere]: Object.values(ItsQuietHereTypes).map(parseCamel),
  [CommEvent.Reminder]: Object.values(ReminderType).map(parseCamel),
};
