import { Autocomplete, Grid } from "@mui/material";
import CardItem from "components/alove/CardItem";
import { ImageUploader } from "components/alove/ImageUploader";
import { addAlert, useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ItemPageContainer from "layouts/itemPageContainer";
import { BoUser } from "models/BO/BoUser";
import { GeneralCode, GeneralCodeType } from "models/content/GeneralCode";
import { CSSetting } from "models/customer-supports/CSSetting";
import { Ticket, TicketPriority } from "models/customer-supports/Ticket";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTicketDO, getSLASettingsDO } from "utils/customerSupportUtils";
import { getGeneralCodes } from "utils/generalCodesUtils";
import { camelCaseToCapitalizedWords, numbers } from "utils/globalsUtils";
import { getSystemUsersDO } from "utils/systemUsersUtils";
import { TicketInfo } from "./components/ticketInfo";
import { DeviceInfo } from "./components/deviceInfo";

const MDInput = require("components/MDInput").default;

export const CreateNewTicket = () => {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState<Partial<Ticket>>({});

  useEffect(() => {
    const agent = JSON.parse(localStorage.getItem("agent") || "{}");
    setTicket({ ...ticket, userAgent: agent });
  }, []);

  const createTicket = () => {
    localStorage.setItem("agent", JSON.stringify(ticket.userAgent));
    createTicketDO(ticket).then(() => {
      navigate(-1);
      addAlert(dispatch, "Ticket created successfully");
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ItemPageContainer
        navItems={[{ label: "New Ticket", ref: "tickets" }]}
        actions={{
          itemName: "New Ticket",
          itemNumber: "",
          actionsTitle: "Create New Ticket",
          actions: [
            {
              title: "Create",
              onClick: createTicket,
              variant: "contained",
            },
          ],
        }}
      >
        <CardItem title="Ticket Information" id="ticketInformation">
          <TicketInfo ticket={ticket} setTicket={setTicket} />
        </CardItem>
        <CardItem title="Device Information" id="deviceInformation">
          <DeviceInfo
            agent={ticket.userAgent || ({} as any)}
            setAgent={(device: any) => setTicket({ ...ticket, userAgent: device })}
          />
        </CardItem>
      </ItemPageContainer>
    </DashboardLayout>
  );
};
