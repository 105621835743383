import axios from "axios";
import { Brand } from "models/BO/Brand";
import { EmailTemplate } from "models/content/Comm";

const serverHost = location.hostname.startsWith("dev")
  ? "https://devapi.a-dmin.ai"
  : "https://api.a-dmin.ai";

export let networkGlobals = {
  host: window.localStorage.getItem("currentHost") || "prod",
  brand: JSON.parse(window.localStorage.getItem("currentBrand") || "{}") as Brand,
  errorCallbacks: [] as Function[],
  serverHost: location.hostname === "localhost" ? "http://localhost:5001" : serverHost,
};

export const makeRequest = (
  url,
  {
    method,
    body,
    auth,
    basePath,
    withoutError,
    brandId,
  }: {
    method?: string;
    body?: any;
    auth?: boolean;
    basePath?: string;
    withoutError?: boolean;
    brandId?: number;
  } = {}
) => {
  if (!url.includes("host")) {
    url += url.includes("?") ? "&" : "?";
    url += `host=${networkGlobals.host}`;
  }

  if (!url.includes("brandID") && networkGlobals.brand.id) {
    url += url.includes("?") ? "&" : "?";
    url += `brandID=${brandId || networkGlobals.brand.id}`;
  }

  console.log("body: ", body);
  console.log("url: ", url);
  const headers = {
    "X-env": networkGlobals.host,
    "X-brand": brandId || networkGlobals.brand.id,
  };
  headers["Authorization"] = localStorage.getItem("BOToken");

  return (
    axios({
      method: method || "GET",
      url: (basePath || `${networkGlobals.serverHost}/api/`) + url,
      data: body,
      headers,
    })
      .then((res) => res.data)
      // .then((res) => console.log('res: ', res))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("BOToken");
          window.location.href = "#";
          return;
        }
        if (!withoutError) {
          console.log(err);
          networkGlobals.errorCallbacks.forEach((cb) => cb(err));
        } else {
          throw err;
        }
      })
  );
};

const getPort = (url): string => {
  if (location.hostname === "localhost") {
    url = url.split(":5001")[0];
    switch (networkGlobals.host) {
      case "dev":
        return `${url}:5001`;
      case "staging":
        return `${url}:5002`;
      case "prod":
        return `${url}:5003`;
    }
  }
  return url;
};

export const getBucket = () => {
  let bucket = "";
  switch (networkGlobals.host) {
    case "dev":
      bucket = "alove-dev-assets";
      break;
    case "staging":
      bucket = "mujual-staging-assets";
      break;
    case "prod":
      bucket = "mujual-prod-assets";
      break;
    default:
      break;
  }
  return bucket;
};

export const getChatBucket = () => {
  let bucket = "";
  switch (networkGlobals.host) {
    case "dev":
      bucket = "alove-dev-chat-media";
      break;
    case "staging":
      bucket = "mujual-staging-chat-media";
      break;
    case "prod":
      bucket = "mujual-prod-chat-media";
      break;
    default:
      break;
  }
  return bucket;
}

export const getLambadaSubdomain = () => {
  let host = "";
  switch (networkGlobals.host) {
    case "dev":
      host = "alove-dev";
      break;
    case "staging":
      host = "mujual-staging";
      break;
    case "prod":
      host = "mujual";
      break;
    default:
      break;
  }
  return host;
};

export const uploadImage = async (file: File, folder:string) => {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("folder", folder);
  return makeRequest("utils/images", {
    method: "POST",
    body: formData,
  });
};

export const fileLink = (url: string) => {
  return `${networkGlobals.serverHost}/api/utils/file?brandID=${networkGlobals.brand.id}&host=${
    networkGlobals.host
  }&token=${localStorage.getItem("BOToken")}&url=${url}`;
};

export const getEmailTemplate = (template: EmailTemplate) => {
  return axios.get(
    `https://${getBucket()}.s3.us-east-1.amazonaws.com/public/sharedDocs/${
      networkGlobals.brand.folder
    }/en/emails/${template}.html`
  );
};

export const getCustomerSupportImage = (url: string, identityId: string) => {
  return fileLink(`https://${getChatBucket()}/private/${identityId}/${url}`)
}