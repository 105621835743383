import { LocalFireDepartment } from "@mui/icons-material";
import { capitalize, Grid, ListItem, Typography } from "@mui/material";
import { Introduction } from "models/introduction/Introduction";
import { IntroductionLog } from "models/introduction/IntroLog";
import { useState, useEffect } from "react";
import {
  camelCaseToCapitalizedWords,
  formatDateTime,
  snakeCaseToCapitalizedWords,
} from "utils/globalsUtils";
import { getIntroductionLogs } from "utils/introductionUtils";

export const IntroductionLogs = ({id, introduction}: {id, introduction: Introduction }) => {
  const [logs, setLogs] = useState([] as IntroductionLog[]);
  useEffect(() => {
    getIntroductionLogs(id).then((res) => setLogs(res));
  }, [id]);
  return (
    <Grid>
      {logs.map((log, index) => (
        <Grid key={index} container sx={{mb:1}}>
          <Grid item xs={2}>
            <Typography variant="body2">{formatDateTime(log.date)}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">
                {log.author ? introduction?.[log.author +"User"].firstName : ""}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" fontWeight="bold" color="secondary"
            >{camelCaseToCapitalizedWords(log.type)}</Typography>
          </Grid>
          <Grid item xs={true}>
            <Typography variant="body2">{snakeCaseToCapitalizedWords(log.message)}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
