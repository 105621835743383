import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Divider,
  IconButton,
  TextField,
  CircularProgress,
  Stack,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useParams, useNavigate } from "react-router-dom";
import ItemPageContainer from "layouts/itemPageContainer";
import CardItem from "components/alove/CardItem";
import { camelCaseToCapitalizedWords, formatDate, isValidUUID } from "utils/globalsUtils";
import { SMEHeader } from "layouts/itemPageContainer/userItem/profileHeader";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import { Brand } from "models/BO/Brand";
import { PermissionType } from "models/BO/BoRole";
import { Profile } from "models/profile/Profile";
import { PackageDetails } from "layouts/endUsers/viewEndUser/components/package";
import { getByUserId } from "utils/enduserUtils";
import {
  getTicketDO,
  getSLASettingsDO,
  addResponse,
  getTicketResponses,
  updateTicketStatusDO,
  updateTicket,
} from "utils/customerSupportUtils";
import { avatarUrl, formatDateTime } from "utils/globalsUtils";
import { EndUser } from "models/profile/EndUser";
import ReAssignModal from "./components/reAssignModal";
import { makeStyles, Modal, Backdrop, Fade } from "@material-ui/core";
import { networkGlobals } from "utils/networkUtils";
import { AppFeature, hasFeature } from "utils/settingsUtils";
import { ReporterDetails } from "./components/reporterDetails";
import { Ticket, TicketPriority, TicketStatus } from "models/customer-supports/Ticket";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { GeneralCode } from "models/content/GeneralCode";
import { BoUser } from "models/BO/BoUser";
import { getSystemUserByIDDO, getSystemUsersDO } from "utils/systemUsersUtils";
import { ReportImagesSection } from "./components/imagesSection";
import { ReportConversation } from "./components/reportConversation";
import { ReportInnerConversation } from "./components/reportInnerConversation";
import { DialogType } from "components/alove/AloveDialog";
import { TicketContent } from "./components/ticketContent";

const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

let host: string = "dev";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
}));

function CustomerSupportViewTicket() {
  const pageNumber = "6.3";
  const navigate = useNavigate();
  const { ticketID } = useParams();
  const now = new Date();

  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;

  const [snackbar, setSnackbar] = useState({
    status: false,
    icon: "error",
    color: "error",
    title: "",
  });
  const [profileData, setProfileData] = useState({} as Profile);
  const [ticket, setTicket] = useState({} as Ticket);
  const [newLog, setNewLog] = useState("");
  const [allUsers, setAllUsers] = useState([] as BoUser[]);
  const [userData, setUserData] = useState(new EndUser());
  const [reporteeData, setReporteeData] = useState(new EndUser());
  const [currentStatus, setCurrentStatus] = useState(undefined as GeneralCode | undefined);
  const [brandUsers, setBrandUsers] = useState([] as BoUser[]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("false");
  const [currentSLA, setCurrentSLA] = useState("");
  const [hasPackage, setHaspackage] = useState(false);
  const [isFromSystem, setIsFromSystem] = useState(false);

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  useEffect(() => {
    getData();
    if (hasFeature(AppFeature.premium)) {
      setHaspackage(true);
    }
  }, []);

  const getData = async () => {
    host = networkGlobals.host;

    const ticketData: Ticket = await getTicketDO(ticketID);

    setTicket(ticketData);
    const _isFromSystem = !isValidUUID(ticketData?.profileId);
    setIsFromSystem(_isFromSystem);

    let brands: any = [];
    if (ticketData?.brandId === 100) {
      brands.push(100);
    } else {
      brands.push(ticketData?.brandId);
    }

    const users: BoUser[] = await getSystemUsersDO(null,null,null,true);
    setBrandUsers(users);

    // const allUsers: BoUser[] = await getAllUSersDO("dev");
    setAllUsers(users);

    if (ticketData?.profileId) {
      if (!_isFromSystem) {
        // const reporterID: any = await getByUserId(ticketData?.profileId);

        const { user, profile, profileAssets } = await getByUserId(ticketData?.profileId);

        user.avatar = avatarUrl(ticketData?.profileId, profileAssets?.[0]?.uri);
        setUserData(Object.assign(new EndUser(), user));
        setProfileData(Object.assign(new Profile(), profile));
      } else {
        const user = await getSystemUserByIDDO("", ticketData?.profileId);
        setUserData(Object.assign(new EndUser(), user));
      }
    }
    if (ticketData?.reportee && ticketData.topic.id === 5) {
      // const reporteeID: any = await getUserIDDO(host, ticketData?.reportee);
      const { user, profile, profileAssets } = await getByUserId(ticketData?.reportee);
      user.avatar = avatarUrl(ticketData?.reportee, profileAssets?.[0]?.uri);
      setReporteeData(Object.assign(new EndUser(), user));
    }

    const SLASettings = await getSLASettingsDO();
    const sla = SLASettings.find((sla) => sla.topicId === (ticketData.topic?.id || 1));
    if (!sla) {
      return;
    }


    const createdDate = new Date(ticketData.created);
    const slaDeadline = new Date(createdDate.getTime() + sla!.slaFirst * 60 * 60 * 1000);
    const adjustedNow = new Date(now.getTime() + 3 * 60 * 60 * 1000);
    const timeDiff = slaDeadline.getTime() - adjustedNow.getTime();
    const totalMinutesLeft = timeDiff / (1000 * 60);
    const hours: any = Math.floor(totalMinutesLeft / 60);
    const minutes: any = Math.floor(Math.abs(totalMinutesLeft % 60));
    setCurrentSLA(`${hours} h ${minutes} m`);
  };

  const changeBrandStatus = async (status) => {
    const updateStatus = await updateTicketStatusDO(
      {
        status,
        userId: userData?.id,
      },
      ticketID
    );
    getData();
    addAlert(dispatch, "Ticket status was updated");
  };



  const update = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DashboardLayout>
      <ReAssignModal
        ticket={ticket}
        brandUsers={brandUsers}
        onChange={() => getData()}
      />
      <DashboardNavbar pageTitle={`Support Ticket #${ticketID}`} />
      <ItemPageContainer
        brandLogoIMG={ticket?.screenshot}
        navItems={[
          { label: "Ticket details" },
          { label: "Reporter - Reportee", ref: "reporter" },
          { label: "Package details", ref: "package" },
          { label: "Ticket content", ref: "ticketContent" },
          { label: "Ticket conversation", ref: "ticketActivityLog" },
          { label: "Reporter Details", ref: "details" },
        ]}
        actions={{
          itemName: `${ticket?.topic} Ticket`,
          itemNumber: `Ticket #: ${ticketID}`,
          actionsTitle: "Ticket actions",
          pageNumber,
          actions: [
            {
              title: "Re assign",
              onClick: () => setCurrentDialog(dispatch, DialogType.ReAssignTicket),
              variant: "contained",
              // role: PermissionType.Edit
            },
            {
              title: ticket.internal ? "Make public" : "Make internal",
              onClick: () => updateTicket({ internal: !ticket.internal }, ticketID).then(() => getData()),
              role: PermissionType.Manager,
              
            }
          ],
        }}
        itemStatus={{
          title: "Ticket Status",
          changeStatus: changeBrandStatus,
          item: ticket,
          isManager: currentRole.hasAccess(pageNumber, PermissionType.Edit),
        }}
        itemHeader={
          <SMEHeader
            title="Ticket details"
            showImage={false}
            formData={userData}
            indications={[
              {
                title: "Created:",
                value: formatDate(ticket?.created),
              },
              {
                title: "Assigned to:",
                value: ticket?.assignedTo?.map((p: any, i) => {
                  const user: any = allUsers?.find((u: any) => u.id === p);
                  return user?.firstName + " " + user?.lastName + ", ";
                }),
                fixedSize: 200,
              },
              {
                title: "Updated",
                value: formatDate(ticket?.lastUpdate),
              },
              {
                title: "Topic",
                value: ticket?.topic?.name,
              },
              {
                title: "Sub-Topic",
                hidden: !ticket?.subTopic,
                value: ticket?.subTopic?.name,
              },
              {
                title: "SLA",
                value: currentSLA,
              },
              {
                title: "Priority",
                value: camelCaseToCapitalizedWords(TicketPriority[ticket?.priority]),
              }
            ]}
            saveProfile={update}
          />
        }
      >
        <Card style={{ width: "100%" }}>
          <ReportImagesSection ticket={ticket} userData={userData} reporteeData={reporteeData} />
        </Card>
        {hasPackage && (
          <CardItem title="Package details" id="package">
            <PackageDetails profile={profileData} />
          </CardItem>
        )}

        <CardItem title="Ticket content" defaultOpen id="ticketContent">
          <TicketContent ticket={ticket} />
        </CardItem>
        {!isFromSystem && (
        <CardItem title="End User Conversation" defaultOpen id="ticketActivityLog" halfWidth>
          <ReportConversation ticket={ticket} userData={userData} />
        </CardItem>)}
        <CardItem title="Inner Conversation" defaultOpen id="ticketInnerActivityLog" halfWidth={!isFromSystem}>
          <ReportInnerConversation ticket={ticket} users={allUsers}  />
        </CardItem>
        <CardItem title="Reporter Device" id="details">
          <ReporterDetails ticket={ticket} />
        </CardItem>
      </ItemPageContainer>

      <MDSnackbar
        color={snackbar?.color}
        icon={snackbar?.icon}
        title={snackbar?.title}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} timeout={500}>
          <img src={image} alt="asd" style={{ maxHeight: "90%", maxWidth: "90%" }} />
        </Fade>
      </Modal>
    </DashboardLayout>
  );
}

export default CustomerSupportViewTicket;
