import axios from "axios";
import { BoUser } from "models/BO/BoUser";
import { makeRequest, networkGlobals } from "./networkUtils";
import { Brand } from "models/BO/Brand";
import { camelcaseToSnakeCase } from "./globalsUtils";

/** get list of settings */
export const getSystemUsersDO = async (brandID?, page?, matchmakers?, showAll=false): Promise<BoUser[]> => {
  return makeRequest(`system-users?page=${page}&matchmakers=${matchmakers}&showAll=${showAll}`, {
    brandId: brandID,
  }).then((data) => data.map((user) => Object.assign(new BoUser(), user)));
};

export const deleteEndUserDO = async (userID) => {
  return makeRequest(`system-users/${userID}`, { method: "DELETE" });
};

/** get list of settings */
export const getSystemUserByIDDO = async (host, userID): Promise<BoUser> => {
  return makeRequest(`system-users/${userID}`);
};

// /** get list of system users */
// export const getSystemUsersBYBrandDO = async (host, brandID) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${networkGlobals.serverHost}/api/systemUsers/byBrandID?host=${host}&brandID=${brandID}`)
//       .then((res) => {
//         resolve(res?.data?.rows);
//       })
//       .catch((error) => {
//         console.error("error from get logs:", error);
//         reject(error);
//       });
//   });
// };

/** get list of end users */
// export const getEndUsersBYBrandDO = async (host, brandID) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${networkGlobals.serverHost}/api/systemUsers/endUsers?host=${host}&brandID=${brandID}`)
//       .then((res) => {
//         resolve(res?.data?.rows);
//       })
//       .catch((error) => {
//         console.error("error from get logs:", error);
//         reject(error);
//       });
//   });
// };

// export const getSystemUsersNamesDO = async (host, brandID) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${networkGlobals.serverHost}/api/systemUsers/names?host=${host}`)
//       .then((res) => {
//         resolve(res?.data?.rows);
//       })
//       .catch((error) => {
//         console.error("error from get logs:", error);
//         reject(error);
//       });
//   });
// };

// export const getBrandsDO = async (host): Promise<Brand[]> => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${networkGlobals.serverHost}/api/systemUsers/brands?host=${host}`)
//       .then((res) => {
//         resolve(res?.data?.rows);
//       })
//       .catch((error) => {
//         console.error("error from get brands:", error);
//         reject(error);
//       });
//   });
// };

/** post new user */
export const postNewUserDO = async (host, data) => {
  const { avatar, ...withoutAvatar } = data;
  return makeRequest(`system-users`, { method: "POST", body: withoutAvatar });
  // return new Promise((resolve, reject) => {
  //     const { avatar, ...withoutAvatar} = data
  //     axios.post(`${networkGlobals.serverHost}/api/systemUsers/?host=${host}`, { data:withoutAvatar, host })
  //         .then(res => {
  //             console.log('res post new system user DO; ', res?.status);
  //             resolve("OK");
  //         })
  //         .catch(error => {
  //             console.error('error from post new system user DO:', error)
  //             reject(error);
  //         })
  // })
};

/** update user */
export const updateUserDO = async (host: string, data) => {
  const { avatar, ...withoutAvatar } = data;
  return makeRequest(`system-users/${data.id}`, { method: "PUT", body: withoutAvatar });
  // return new Promise((resolve, reject) => {
  //     const { avatar, ...withoutAvatar} = data
  //     axios.put(`${networkGlobals.serverHost}/api/systemUsers/`, { data:withoutAvatar, host })
  //         .then(res => {
  //             console.log('res update system user DO; ', res?.status);
  //             resolve("OK");
  //         })
  //         .catch(error => {
  //             console.error('error from update system user DO:', error);
  //             reject(error);
  //         })
  // })
};
export const getUsersStatsDO = async (host, brandID): Promise<any> => {
  return makeRequest(`system-users/stats?brandID=${brandID || ""}`);
};
