import { Grid, Stack, Badge, Divider } from "@mui/material"
import { useNavigate } from "react-router-dom";

const MDTypography = require("components/MDTypography").default;

export const ReportImagesSection = ({ ticket, userData, reporteeData }) => {

    const navigate = useNavigate();

    return  <Grid
    flexDirection="row"
    container
    alignItems={"end"}
    justifyContent="center"
    sx={{ px: 4 }}
  >
    <Grid
      item
      xs={5.8}
      lg={5.8}
      sx={{ my: 2 }}
      flexWrap="nowrap"
      flexDirection="row"
      container
      gap={3}
    >
      <Stack direction="row">
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <img
            src={userData?.avatar}
            style={{
              height: "84px",
              width: "84px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </Badge>
      </Stack>
      <Grid
        container
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignContent="center"
      >
        <MDTypography variant="h5" fontWeight="medium">
          Reporter:
        </MDTypography>
        <MDTypography variant="title" color="inherit" noWrap>
          &nbsp;
        </MDTypography>
        {userData?.firstName ? (
          <MDTypography
            variant="h5"
            fontWeight="light"
            onClick={() => navigate(`/endUsers/${userData?.id}`)}
            sx={{ color: "#49AAEF", cursor: "pointer" }}
          >
            {`${userData?.firstName} ${userData?.lastName ? userData?.lastName : ""}`}
          </MDTypography>
        ) : null}
      </Grid>

    </Grid>
    <Grid
      item
      xs={0.2}
      lg={0.2}
      sx={{ my: 2 }}
      // flexWrap="nowrap"
      // flexDirection="row"
      // container
      // gap={3}
    >

      <Divider orientation="vertical" sx={{ bgcolor: "black" }} flexItem />
    </Grid>

    <Grid
      item
      xs={5.8}
      lg={5.8}
      sx={{ my: 2 }}
      flexWrap="nowrap"
      flexDirection="row"
      container
      gap={3}
    >
      {ticket.topic?.name === "reportAbuse" ? (
        <>
          <Stack direction="row">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <img
                src={reporteeData?.avatar}
                style={{
                  height: "84px",
                  width: "84px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </Badge>
          </Stack>
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignContent="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Reportee:
            </MDTypography>
            <MDTypography variant="title" color="inherit" noWrap>
              &nbsp;
            </MDTypography>
            {userData?.firstName ? (
              <MDTypography
                variant="h5"
                fontWeight="light"
                onClick={() => navigate(`/endUsers/${reporteeData?.id}`)}
                sx={{ color: "#49AAEF", cursor: "pointer" }}
              >
                {`${reporteeData?.firstName} ${
                  reporteeData?.lastName ? reporteeData?.lastName : ""
                }`}
              </MDTypography>
            ) : null}
          </Grid>
        </>
      ) : null}
    </Grid>
  </Grid>
}