import { capitalize } from "@mui/material";
import { Statusable } from "../BO/BoUser";
import { BaseContent, ContentStatus } from "./Content";

export enum ScreenType {
  Popup = 0,
  Curtain = 1,
  FullScreen = 2,
  SliderScreen = 3,
  CardsScreen = 4,
  MenuScreen = 5,
  TabsScreen = 6,
  ContentScreen = 7,
  InstructionScreen = 8,
}

export enum ScreenEvent {
  none = "none",
  afterApprove = "afterApprove",
  profileOpen = "profileOpen",
  addChild = "addChild",
  welcome = "welcome",
  noSuggestions = "noSuggestions",
  paymentSuccessful = "paymentSuccessful",
  extendSuggestion = "extendSuggestion",
  settings = "settings",
  whyWeAsksThis = "whyWeAsksThis",
  endOnboarding = "endOnboarding",
  systemShutdown = "systemShutdown",
  preLoginEnd = "preLoginEnd",
  meSnoozed = "meSnoozed",
  declineSuggestionWarning = "declineSuggestionWarning",
  mmInformUnmatch = "mmInformUnmatch",
}

export enum NextScreenEvent {
  none = "none",
  genericScreen = "genericScreen",
  appScreen = "appScreen",
  custom = "custom",
}

export enum ScreenStatus {
  draft = 1,
  active = 2,
  deactive = 3,
}

export type ScreenStyles = {
  button1Color?: string;
  button2Color?: string;
  cacnelColor?: string;
  showAppIcon?: boolean;
  showPartners?: boolean;
  iconSize?: number;
  titleSize?: number;
  subtitleSize?: number;
  desktopImage?: string;
};

export interface ScreenCard {
  title: string;
  body: string;
  image: string;
}

export interface MenuScreenItem {
  title: string;
  subtitle: string;
  panel: string;
  disabled: boolean;
  nextEventType: NextScreenEvent;
  actionUrl: string;
}

export interface InstructionItem {
  title: string;
  body: string;
  badImage: string;
  goodImage: string;
}

export class ScreenModel  extends BaseContent {
  id: string;
  name: string;
  event: ScreenEvent;
  title: string;
  subtitle: string;
  body: string;
  button1Title: string;
  button2Title: string;
  cancelTitle: string;
  note: string;
  image: string;
  brandId: number;
  param: string;
  type: ScreenType;
  fieldsOrder: string[];
  actionUrl: string;
  actionUrl2: string;
  styles: ScreenStyles;
  categoryId: number;
  nextEventType: NextScreenEvent;
  nextEventType2: NextScreenEvent;
  extra:any;

  get cards(): ScreenCard[] {
    return (this.extra || []) as ScreenCard[]
  }

  set cards(cards: ScreenCard[]) {
    this.extra = cards;
  }

  get menuItems(): MenuScreenItem[] {
    return (this.extra || []) as MenuScreenItem[];
  }

  set menuItems(items: MenuScreenItem[]) {
    this.extra = items;
  }

  get slides(): String[] {
    return (this.extra || []) as String[];
  }

  set instructionItems(items: InstructionItem[]) {
    this.extra = items;
  }

  get instructionItems(): InstructionItem[] {
    if (!this.extra) {
      this.extra = [];
    }

    return (this.extra) as InstructionItem[];
  }
}

export const appPages = [
  "home",
  "loader",
  "welcome_intro",
  "login",
  "login_email",
  "login_phone",
  "login_otp",
  "onboarding_home",
  "questionnaires",
  "me",
  "bio_preferences",
  "questionnaires_start",
  "questionnaires_end",
  "home_lora_chat",
  "home_pre_login_lora",
  "pre_login_end",
  "public_profile",
  "public_profile_images",
  "privacy_policy",
  "predicator_feedback",
  "matchmaker_page",
  "matchmaker_info",
  "mood_update",
  "settings_profile_edit",
  "settings_preferences",
  "settings_parent",
  "settings_general",
  "settings_phone_change",
  "settings_phone_otp",
  "settings_phone_change_success",
  "settings_email_change",
  "settings_email_otp",
  "settings_email_change_success",
  "settings_language",
  "settings_router",
  "settings_support_center",
  "settings_notifications",
  "delete_account",
  "snooze_account",
  "snooze_account_details",

  "support_get_started_guide",
  "support_terms_of_use",
  "support_faq",
  "support_contact_us",
  "introductions",
  "potential_matches",
  "matches",
  "conversation",
  "conversation_call",
  "break_the_ice",
  "matches_accepted",
  "report",
  "report_details",
  "contact_us_option_technical_issues",
  "contact_us_option_subscription_and_payment",
  "contact_us_option_feedback",
  "contact_us_option_general_inquiry",
  "payments_intro",
  "payments_details",
  "payments_tokens",
  "payments_methods",
  "payments_features",
  "payments_history",
  "payments_add_card",
  "payments_pick_payment_method",
];
