import React, { useState, useEffect } from "react";
// import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import { postLogDO } from "utils/logsUtils";
import { Grid, Autocomplete, TextField, IconButton, Dialog } from "@mui/material";
import { addAlert, useMaterialUIController } from "context";
import { updateAssigneeDO } from "utils/customerSupportUtils";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";

const MDButton = require("components/MDButton/index.js").default;
const MDInput = require("components/MDInput/index.js").default;

export default function ReAssignModal(props) {
  const { ticket, brandUsers, onChange } = props;
  const [controller, dispatch] = useMaterialUIController();

  const [assignee, setAssignee] = useState([] as string[]);
  useEffect(() => {
    setAssignee(ticket.assignedTo);
    }, [ticket]);

  const updateAssignee = async () => {
    const updateRes = await updateAssigneeDO(assignee, ticket.id);
    addAlert(dispatch, "Ticket has been re-assigned");
    onChange();
  };

  return (
    <AloveDialog
      dialogTitle="Re Assign"
      dialogType={DialogType.ReAssignTicket}
      dialogConfirm="Re Assign"
      handleConfirm={updateAssignee}
      valid={assignee?.length > 0}
    >
      <Autocomplete
        sx={{ mt: 1, minWidth: 500 }}
        multiple
        isOptionEqualToValue={(positions, value) => positions.first_name === value.first_name}
        onChange={(e, v) => setAssignee(v.map((user) => user.id))}
        defaultValue={brandUsers
          .filter((user) => ticket?.assignedTo?.includes(user.id))
          .map((user) => user)}
        options={brandUsers ? brandUsers : []}
        getOptionLabel={(option) => option?.first_name + " " + option?.last_name}
        renderInput={(params) => <MDInput {...params} variant="outlined" />}
      />
    </AloveDialog>
  );
}
