import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getShidduchRequests } from "utils/matchmakerUtils";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  TextField,
  Grid,
  Card,
  Box,
  List,
  ListItem,
} from "@mui/material";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import { addAlert, useMaterialUIController } from "context";
import { getAdminStats } from "utils/matchmakerUtils";
import { TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BO/BoRole";
import {
  getCustomerSupportTopicsDO,
  getSLASettingsDO,
  updateAssigneeDO,
  updateCSSetting,
  updateTicketStatusDO,
} from "utils/customerSupportUtils";
import { Ticket } from "models/customer-supports/Ticket";
import { BoUser } from "models/BO/BoUser";
import { getSystemUsersDO } from "utils/systemUsersUtils";
import { GridColDef } from "@mui/x-data-grid";
import { CSSetting } from "models/customer-supports/CSSetting";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";
const MDSnackbar = require("components/MDSnackbar").default;
const MDInput = require("components/MDInput").default;

const host = "dev";

const shouldColorBackground = (request) =>
  request.status <= 9 &&
  (request.statusReason?.initiatorStatus || request.statusReason?.responderStatus);

const action = (request, refresh) => {};

function CustomerSupportSettings() {
  const pageNumber = "6.2";
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentRole, currentBrand } = controller;
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);

  const [users, setUsers] = useState([] as BoUser[]);
  const [allUsers, setAllUsers] = useState([] as BoUser[]);
  const [snackbar, setSnackbar] = useState({ status: false, color: "", icon: "", title: "" });
  const [refresh, setRefresh] = useState(false);

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const getUsers = async (brandID) => {
    let brands = [] as any;
    if (brandID === 100) {
      brands.push(100);
    } else {
      brands.push(100);
      brands.push(brandID);
    }
    console.log("brands: ", brands);

    const users = await getSystemUsersDO(null,0, false);
    setUsers(users);
    setAllUsers(users);
  };

  const getSettings = async (host, filters, page) => {
    console.log("filters: ", filters);
    console.log("page: ", page);

    const brandID = filters?.brand;

    const SLASettings = await getSLASettingsDO();
    console.log("SLASettings: ", SLASettings);

    const topics = await getCustomerSupportTopicsDO();
    console.log("topics: ", topics);

    SLASettings.forEach((sla, i) => {
      sla.topic = topics.find((t) => t.id === sla.topicId);
    });

    topics.forEach((t, i) => {
      let obj = {} as any;
      const sla = SLASettings.find((sla) => sla.topicId === t.id);
      if (!sla) {
        SLASettings.push(
          Object.assign(new CSSetting(), {
            topic: t,
            id: "new" + Math.random(),
          })
        );
      }
      // obj.id = sla?.id
      // obj.category = t.name;
      // obj.topic_id = t?.id;
      // obj.sla_first = sla?.slaFirst;
      // obj.sla_completion = sla?.slaCompletion;
      // obj.assignee = sla?.assignee;
      // obj.created = sla?.created;
      // tableData.push(obj);
    });

    getUsers(brandID);
    return SLASettings;
  };

  const updateValue = async (newRow, oldRow) => {
    console.log("newRow: ", newRow);
    console.log("oldRow: ", oldRow);

    const newOBJ = { ...newRow, slaCompletion: Number(newRow?.slaCompletion), topicId: newRow?.topic?.id };
    updateCSSetting(newOBJ, newRow?.id).then((res) =>{
      addAlert(dispatch, "SLA settings updated successfully")
      if (newRow.id.toString().startsWith("new")) {
        setRefresh(!refresh);
      }
  });
    return newRow;
  };

  const SLAcolumns: GridColDef<CSSetting>[] = [
    {
      headerName: "Topic",
      field: "topic.name",
      valueGetter: (v, row) => camelCaseToCapitalizedWords(row.topic?.name),
      flex: 1,
    },
    {
      headerName: "SLA first (hours)",
      field: "slaFirst",
      flex: 1,
      editable: currentRole.hasAccess(pageNumber, PermissionType.Edit),
    },
    {
      headerName: "SLA Completion (hours)",
      field: "slaCompletion",
      flex: 1,
      editable: currentRole.hasAccess(pageNumber, PermissionType.Edit),
    },
    {
      headerName: "Assignee",
      field: "assignee",
      flex: 5,
      renderCell: (params) => {
        return (
          <Autocomplete
            key={params?.row?.id}
            sx={{ mt: 1 }}
            size="small"
            multiple
            disabled={!currentRole.hasAccess(pageNumber, PermissionType.Edit)}
            onChange={(e, v) => updateAssignee(v, params.row)}
            defaultValue={params?.row?.assignee || []}
            options={users?.map((u) => u.id) || []}
                          getOptionLabel={(option) =>users.find((u) => u.id === option)?.fullName || ""}
            renderInput={(params) => <MDInput {...params} />}
          />
        );
      },
    },
  ];

  const updateAssignee = async (assignees, params) => {
    const data = { ...params, assignee: assignees };
    const updateRes = await updateCSSetting(data, params?.id)
    addAlert(dispatch, "SLA settings updated successfully")
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        sx={{
          "& .urgent": {
            color: "#F4357A",
          },
          "& .hidden": {
            color: "transparent !important",
          },
        }}
      >
        <TablePage
          title="SLA Settings"
          refresh={refresh}
          table={{
            columns: SLAcolumns,
            loadNext: getSettings,
            processRowUpdate: updateValue,
          }}
          filters={
            [
              // {
              //   label: "brand",
              //   hidden: isAdmin ? false : null,
              //   options: brands,
              //   defaultValue: brands[0],
              //   disableClearable: true
              //   // renderFilter: renderBrands,
              //   // clearOtherFilters: true,
              // },
              // {
              //   label: "Screen",
              //   renderFilter: renderBrand,
              //   clearOtherFilters: true,
              // }
            ]
          }
        />
      </Box>
      <MDSnackbar
        color={snackbar.color || "success"}
        icon={snackbar.icon || "check"}
        title={snackbar.title || ""}
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}

export default CustomerSupportSettings;
