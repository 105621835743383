import {
  Divider,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HIDDEN_ATTRIBUTES } from "utils/matchmakerUtils";
import { formatDate, formtHeight, groupBy, snakeCaseToCamelCase, uniqueBy } from "utils/globalsUtils";
import { loadAttributes } from "utils/attributesUtil";
import { visibiltyOptions } from "utils/attributesUtil";
import { Attribute } from "models/profile/Attribute";
import { Introduction } from "models/introduction/Introduction";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BO/BoRole";
import CardItem from "components/alove/CardItem";
import { getQuestionDO, questionnarieListDo } from "utils/questionsUtils";
import { AttributePolicy, Profile } from "models/profile/Profile";
import { getProfileResponses } from "utils/enduserUtils";
import {  DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { relative } from "path";
import form from "layouts/pages/users/new-user/schemas/form";
import { T } from "components/alove/Translator";
import { fileLink } from "utils/networkUtils";
import { ProfileResponse } from "models/profile/ProfileResponse";
import { ResponseCell } from "layouts/introductions/viewIntroduction/components/cell";

const MDTypography = require("components/MDTypography").default;

const findPrefFromBio = (attrs, bio) => {
  const attr = attrs.find((a) =>
    a.responses?.find((r) => r.bios?.find((b) => b?.bio_attr_id == bio))
  );
  if (!attr) return null;
  return attr;
};

const isAttributesMatch = (bio: Attribute, pref: Attribute, bioValue, prefValue) => {
  if (!bioValue || !prefValue) return null;
  const bioResponses = bio.responses?.filter((r) => bioValue.includes(r.k)) || [];
  const prefResponses = pref.responses?.filter((r) => prefValue.includes(r.k)) || [];

  return prefResponses.some((prefResponse) =>
    prefResponse.bios?.some((prefBio) =>
      bioResponses.some((bioResponse) => bioResponse.k === prefBio.bio_response_id)
    )
  );
};

const headerCell = (value) => (
  <Grid
    xs={6}
    item
    display="flex"
    justifyContent="space-between"
    flexDirection="column"
    alignItems="center"
    gap={2}
  >
    <div></div>
    <MDTypography style={{ fontSize: "16px" }} fontWeight="bold" textAlign="center" lineHeight="1">
      {value}
    </MDTypography>

    <div
      style={{
        height: "3px",
        width: "30%",
        borderRadius: "7px",
        marginBottom: "25px",
      }}
    />
  </Grid>
);

export const BioPrefList = ({ profile }: { profile: Profile }) => {
  const [attributes, setAttributes] = useState([] as Attribute[]);
  const [questionnaires, setQuestionnaires] = useState([] as any);
  const [profileResponses, setProfileResponses] = useState([] as ProfileResponse[]);
  const [cellHover, setCellHover] = useState("");
  const [folded, setFolded] = useState(true);

  useEffect(() => {
    loadAttributes().then((attrs) => {
      setAttributes(attrs);
    });

    questionnarieListDo().then((questionnaires) => {
      setQuestionnaires(questionnaires);
    });

    getProfileResponses(profile.profileId!).then((responses) => {
      setProfileResponses(responses);
    });
  }, []);
  const groupped = groupBy(attributes, (x) => x.attr_group);

  const cell = (value: string, align: string, key?: string) => {
    return (
      <Grid
        container
        borderRadius={1}
        item
        xs
        flexDirection={align == "right" ? "row-reverse" : "row"}
        justifyContent="center"
        alignItems={"center"}
        onMouseEnter={() => setCellHover(key || "")}
        wrap="nowrap"
        gap={2}
      >
        <MDTypography style={{ fontSize: "12px" }} lineHeight="1" textAlign={align}>
          {value}
        </MDTypography>
        {align != "center" && (
          <Grid
            item
            xs={true}
            alignItems={"center"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <Divider
              style={{
                color: "black",
                borderStyle: "dotted",
                borderWidth: "2px",
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const getAttrArray = () => {
    return (groupped["bio"] || [])
      .filter((attr) => !HIDDEN_ATTRIBUTES.includes(attr.attr_name) || attr.attr_name == "height")
      // .filter((attr) => profile.attributesPolicy?.[attr.attr_name] != AttributePolicy.autofilled)
      .map((attr: Attribute) => {
        const initiatorBio = profile?.attributesValues?.[attr.attr_name]?.join(",");
        if (!initiatorBio) return null;

        let initiatorBioTxt = attr.responses
          ?.filter(
            (r) =>
              profile.attributesValues[attr.attr_name]?.includes(r.k) ||
              profile?.attributesValues[attr.attr_name]?.includes(r.id) ||
              profile?.attributesValues[attr.attr_name]?.includes(r.id.toString())
          )
          ?.map((r) => r.txt)
          .join(", ");

        const prefAttribute = findPrefFromBio(groupped["pref"], attr.attr_name);
        const initiatorPref = profile?.attributesValues[prefAttribute?.attr_name]?.join(", ");

        let initiatorPrefTxt = prefAttribute?.responses
          ?.filter(
            (r) =>
              profile?.attributesValues[prefAttribute?.attr_name]?.includes(r.k) ||
              profile?.attributesValues[prefAttribute?.attr_name]?.includes(r.id)
          )
          ?.map((r) => r.txt)
          .join(", ");

        const initiatorMatch = isAttributesMatch(
          attr,
          prefAttribute,
          profile?.attributesValues[attr.attr_name],
          profile?.attributesValues[prefAttribute?.attr_name]
        );


        if (attr.attr_name == "height") {
          initiatorBioTxt = formtHeight(Number(initiatorBio));
          initiatorPrefTxt = initiatorPref?.split(",").map((v) => formtHeight(Number(v))).join(" - ");
        }


        return {
          id: attr.editing_question_txt || attr.attr_name,
          bioName: attr.editing_question_txt,
          initiatorBio: initiatorBioTxt || initiatorBio?.toString(),
          initiatorPrefernce: initiatorPrefTxt || initiatorPref?.toString(),
          initiatorMatch,
          batchId: attr.related_batch_id,
        };
      })
      .filter((r) => r)
      .sort((a, b) => a.bioName.localeCompare(b.bioName));
  };

  const addResponses = (attrs) => {
    console.log("attrs", attrs);
    for (const response of profileResponses) {
      if (!response.profileAttr && response.batchId != "12") {
        attrs.push({
          id: response.batchContentId,
          bioName: response.question,
          initiatorBio: response.response.join ? response.response.join(", ") : response.response,
          ...response,
        });
      }
    }
    return uniqueBy(attrs, "id");
  };

  return (
    <Grid>
      <Grid
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        sx={{ width: "100%" }}
        style={{ zIndex: 2, position: "relative" }}
      >
        <DataGridPro
          rows={addResponses(getAttrArray())}
          getTreeDataPath={(row) => [
            questionnaires.find((q) => q.id == row.batchId)?.display_name || "Onboarding",
            (row.baid ? `${row.baid} - ` : "") + row.bioName,
          ]}
          treeData
          columns={[
            { field: "initiatorBio", headerName: "Bio", flex: 1, renderCell: (params: GridRenderCellParams) => <ResponseCell {...params} /> },
            { field: "initiatorPrefernce", headerName: "Preferences", flex: 1, renderCell: (params: GridRenderCellParams) => <ResponseCell {...params} /> },
          ]}
          hideFooter
          autoHeight
          groupingColDef={{
            flex: 2,
          }}
        />
      </Grid>
    </Grid>
  );
};
