import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Select,
} from "@mui/material";
import { ScreenEvent, ScreenModel, ScreenType } from "models/content/Screen";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { RootRef, styled } from "@material-ui/core";
import { useEffect } from "react";
import { ImageUploader } from "components/alove/ImageUploader";
import { Setting } from "models/BO/Setting";
import { Slides } from "./slides";
import { MenuForm } from "./menu";

const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;

export const ScreenForm = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  useEffect(() => {
    screen.styles ||= {};
    screen.styles.titleSize ||= screen.type == ScreenType.Popup ? 28 : 36;
    screen.styles.subtitleSize ||= screen.type == ScreenType.Popup ? 18 : 24;
    screen.styles.iconSize ||= screen.type == ScreenType.Popup ? 100 : 150;

    if (!screen.fieldsOrder) {
      screen.fieldsOrder = [
        "image",
        "title",
        "subtitle",
        "body",
        "button1Title",
        "button2Title",
        "cancelTitle",
        "note",
      ];
    setScreen(screen);
    }
  }, [screen]);

  const onDragEnd = (result) => {
    let itemsNew = screen.fieldsOrder.filter((field, index) => index !== result.source.index);
    itemsNew.splice(result.destination.index, 0, screen.fieldsOrder[result.source.index]);
    setScreen({ ...screen, fieldsOrder: itemsNew });
  };

  const item = (key, child, index) => {
    return (
      <Draggable key={key} draggableId={key} index={index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <ListItem>
              <Grid
                container
                my={1}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                {screen.type != ScreenType.SliderScreen && screen.type != ScreenType.MenuScreen && (
                  <Grid item xs={1} xl={1}>
                    <ListItemIcon>
                      <MenuIcon />
                    </ListItemIcon>
                  </Grid>
                )}
                <Grid item xs={11}>
                  {child}
                </Grid>
              </Grid>
            </ListItem>
          </div>
        )}
      </Draggable>
    );
  };

  const elements: { [key: string]: React.ReactNode } = {
    image: (
      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <ImageUploader
            initImage={screen.image ? [screen.image] : []}
            folder="screensImages"
            onUploaded={(image: string[]) =>
              setScreen({ ...screen, image: image[0], styles: { ...screen.styles, showAppIcon: false, showPartners: false } })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={screen.styles?.showAppIcon || false}
            onChange={(e) =>
              setScreen({ ...screen, styles: { ...screen.styles, showAppIcon: e.target.checked, showPartners: false } })
            }
          />
          <MDTypography variant="body2">Show App Icon</MDTypography>
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={screen.styles?.showPartners || false}
            onChange={(e) =>
              setScreen({ ...screen, styles: { ...screen.styles, showPartners: e.target.checked, showAppIcon: false } })
            }
          />
          <MDTypography variant="body2">Show Partners</MDTypography>
        </Grid>

        <Grid item xs={2}>
          <MDInput
            fullWidth
            label="Image Size"
            type="number"
            value={screen.styles?.iconSize || 100}
            onChange={(e: any) =>
              setScreen({ ...screen, styles: { ...screen.styles, iconSize: Number(e.target.value) } })
            }
          />
        </Grid>
      </Grid>
    ),
    title: (
      <Grid item container spacing={5}>
        <Grid item xs={9}>
          <MDInput
            fullWidth
            label="Screen Title"
            value={screen.title || ""}
            onChange={(e: any) => setScreen({ ...screen, title: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <MDInput
            fullWidth
            label="Title Size"
            type="number"
            value={screen.styles?.titleSize || 28}
            onChange={(e: any) =>
              setScreen({ ...screen, styles: { ...screen.styles, titleSize: Number(e.target.value) } })
            }
          />
        </Grid>
      </Grid>
    ),
    subtitle: (
      <Grid item container spacing={5}>
        <Grid item xs={9}>
          <MDInput
            fullWidth
            label="Screen Subtitle"
            value={screen.subtitle || ""}
            multiline
            onChange={(e: any) => setScreen({ ...screen, subtitle: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <MDInput
            fullWidth
            label="Subtitle Size"
            type="number"
            value={screen.styles?.subtitleSize || 18}
            onChange={(e: any) =>
              setScreen({ ...screen, styles: { ...screen.styles, subtitleSize: Number(e.target.value) } })
            }
          />
        </Grid>
      </Grid>
    ),
    body:
      screen.type == ScreenType.SliderScreen ? (
        <Slides screen={screen} setScreen={setScreen} />
      ) : screen.type == ScreenType.MenuScreen ? (
        <MenuForm screen={screen} setScreen={setScreen} />
      ) : (
        <MDInput
          fullWidth
          label="Screen Body"
          value={screen.body || ""}
          multiline
          rows={10}
          onChange={(e: any) => setScreen({ ...screen, body: e.target.value })}
        />
      ),

    button1Title: (
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <MDInput
            fullWidth
            label="Button 1 Title"
            value={screen.button1Title || ""}
            onChange={(e: any) => setScreen({ ...screen, button1Title: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            getOptionLabel={(option) => camelCaseToCapitalizedWords(option)}
            isOptionEqualToValue={(option, value) => option.toLocaleLowerCase() === value.toLocaleLowerCase()}
            value={screen.styles?.button1Color || ""}
            onChange={(e, o) =>
              setScreen({ ...screen, styles: { ...screen.styles, button1Color: o } })
            }
            options={["primary", "secondary", "black", "error", ""]}
            renderInput={(params) => <MDInput {...params} label="Button 1 Color" />}
          />
        </Grid>
      </Grid>
    ),
    button2Title: (
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <MDInput
            fullWidth
            label="Button 2 Title"
            value={screen.button2Title || ""}
            onChange={(e: any) => setScreen({ ...screen, button2Title: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            value={screen.styles?.button2Color || ""}
            getOptionLabel={(option) => camelCaseToCapitalizedWords(option)}
            isOptionEqualToValue={(option, value) => option.toLocaleLowerCase() === value.toLocaleLowerCase()}
            onChange={(e, o) =>
              setScreen({ ...screen, styles: { ...screen.styles, button2Color: o } })
            }
            options={["primary", "secondary", "black", "error", ""]}
            renderInput={(params) => <MDInput {...params} label="Button 2 Color" />}
          />
        </Grid>
      </Grid>
    ),
    cancelTitle: (
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <MDInput
            fullWidth
            label="Cancel Button Title"
            value={screen.cancelTitle || ""}
            onChange={(e: any) => setScreen({ ...screen, cancelTitle: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            value={screen.styles?.cacnelColor || ""}
            getOptionLabel={(option) => camelCaseToCapitalizedWords(option)}
            isOptionEqualToValue={(option, value) => option.toLocaleLowerCase() === value.toLocaleLowerCase()}
            onChange={(e, o) =>
              setScreen({ ...screen, styles: { ...screen.styles, cacnelColor: o } })
            }
            options={["primary", "secondary", "black", "error", ""]}
            renderInput={(params) => <MDInput {...params} label="Cancel Button Color" />}
          />
        </Grid>
      </Grid>
    ),
    note: (
      <MDInput
        fullWidth
        label="Additional Text"
        value={screen.note || ""}
        onChange={(e: any) => setScreen({ ...screen, note: e.target.value })}
      />
    ),
  };

  return (
    <Grid container flexDirection="column" p={4} gap={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <RootRef rootRef={provided.innerRef}>
                <List sx={{}}>
                  {screen.fieldsOrder?.map((field, index) => {
                    return item(field, elements[field], index);
                  })}
                  {provided.placeholder}
                </List>
              </RootRef>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};
