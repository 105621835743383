import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { addAlert, useMaterialUIController } from "context";
import { Setting } from "models/BO/Setting";
import { useState } from "react";
import { addSettingDO } from "utils/settingsUtils";

const MDInput = require("components/MDInput/index.js").default;

export const AddNewSettingDialog = ({ belongs }) => {
  const [controller, dipatch] = useMaterialUIController();
  const [setting, setSetting] = useState({} as Setting);

  const create = async () => {
    setting.belongs = belongs;
    await addSettingDO(setting);
    addAlert(dipatch, "Setting added successfully");
  };

  return (
    <AloveDialog
      dialogTitle="Add New Setting"
      dialogType={DialogType.AddSetting}
      handleConfirm={create}
      dialogConfirm="Add"
      handleDismis={() => setSetting({} as Setting)}
      valid={setting.paramName && setting.paramValue}
    >
      <MDInput
        label="Setting Key"
        placeholder="Enter setting key"
        required
        style={{ width: "300px" }}
        onChange={(e) => setSetting({ ...setting, paramName: e.target.value })}
      />
      <MDInput
        label="Setting Value"
        placeholder="Enter setting value"
        required
        style={{ width: "300px" }}
        onChange={(e) => setSetting({ ...setting, paramValue: e.target.value })}
      />
      <MDInput
        label="Description"
        placeholder="Enter description"
        style={{ width: "300px" }}
        onChange={(e) => setSetting({ ...setting, description: e.target.value })}
      />
    </AloveDialog>
  );
};
