import { GeneralCode } from "models/content/GeneralCode";

export class CSSetting {
    id: number;

    
    slaFirst: number;

    
    slaCompletion: number;

    assignee: number[];

    
    topicId: number;

    
    created: Date;

    
    updated: Date;

    
    brandId: number;

    topic?: GeneralCode;

}