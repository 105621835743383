export enum GeneralCodeType {
  ScreenCategory = 'screenCategory',
  TicketTopic = 'ticketTopic',
  TicketSubTopic = 'ticketSubTopic',

  SystemTicketTopic = 'systemTicketTopic',
  SystemTicketSubTopic = 'systemTicketSubTopic',


  EmailCategory = 'emailCategory',
  
}

export class GeneralCode {
  id: number;
  name: string;
  type: GeneralCodeType;
}
