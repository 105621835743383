import { Grid, Divider, TextField, IconButton, CircularProgress } from "@mui/material"
import logs from "layouts/logs"
import { PermissionType } from "models/BO/BoRole"
import { TicketStatus } from "models/customer-supports/Ticket"
import { useEffect, useState } from "react"
import { addResponse, getTicketResponses } from "utils/customerSupportUtils"
import { Message } from "./message"
import { useMaterialUIController } from "context"
import SendIcon from "@mui/icons-material/Send";

export const ReportConversation = ({ ticket, userData }) => {
    const pageNumber = "6.3";
    const [controller, dispatch] = useMaterialUIController();
    const { currentRole } = controller;

    const [logs, setLogs] = useState([] as any);
    const [loader, setLoader] = useState(false);
    let [newLog, setNewLog] = useState("");

    useEffect(() => {
      if (ticket.id){
        getTickeLogs();
      }
    }, [ticket])

    const getTickeLogs = async () => {
        const data = await getTicketResponses(ticket.id!);
        setLogs(data);
    }


  const sendLog = async (e) => {
    if ((e?._reactName === "onClick" || e?.code === "Enter") && newLog.length > 0) {
      e.preventDefault();
      setLoader(true);
      await addResponse(newLog, userData!.id!, ticket.id!);
      setNewLog("");
      setLoader(false);
      getTickeLogs();
    }
  };

    
    return <Grid container flexDirection="row" alignItems="flex-start" gap={1}>
    <Grid item xs={12} lg={12}>
      {logs
        .filter((l) => l.type != "CS_TICKET" && l.type != "CS_TICKET_CLOSED")
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .map((l, i) => (
          <Message message={l} key={i} identityId={userData?.identityId} />
        ))}
      <Divider />
    </Grid>
    {
      <Grid item xs={6} lg={6}>
        <TextField
          id="standard-name"
          // label="Name"
          variant="outlined"
          sx={{ maxWidth: 350, width: "90vw" }}
          placeholder="Add response"
          disabled={
            !currentRole.hasAccess(pageNumber, PermissionType.Edit) ||
            ticket?.status == TicketStatus.Closed
          }
          onChange={(e) => setNewLog(e.target.value)}
          onKeyDown={(e) => sendLog(e)}
          value={newLog}
          InputProps={{
            endAdornment: (
              <IconButton color="secondary" aria-label="add an alarm">
                {loader ? <CircularProgress size={20} /> : <SendIcon onClick={sendLog} />}
              </IconButton>
            ),
          }}
        />
      </Grid>
    }
  </Grid>
}