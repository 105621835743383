import { BoUser } from "models/BO/BoUser";

export enum TicketLogType {
    message = 0,
    statusChange = 1,
    assigned = 2,
}


export class TicketLog {
    id: number;

    ticketId: number;

    log: string;

    created: Date;

    userId: number;

    type: TicketLogType;

    user: BoUser;
}