import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getShidduchRequests } from "utils/matchmakerUtils";
import { useNavigate } from "react-router-dom";
import { Box, List, ListItem } from "@mui/material";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import { addAlert, setCurrentDialog, useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { getAdminStats } from "utils/matchmakerUtils";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BO/BoRole";
import {
  deleteTicketDO,
  getCustomerSupportTopicsDO,
  getSLASettingsDO,
  getTicketsDO,
} from "utils/customerSupportUtils";
import { getBrandsDO } from "utils/brandsUtils";
import { GeneralCode } from "models/content/GeneralCode";
import { Brand } from "models/BO/Brand";
import { getSystemUsersDO } from "utils/systemUsersUtils";
import { BoUser } from "models/BO/BoUser";
import { camelCaseToCapitalizedWords, formatDate, formatDateOrTime } from "utils/globalsUtils";
import { GridActionsCell, GridColDef } from "@mui/x-data-grid";
import { Ticket, TicketSource, TicketStatus } from "models/customer-supports/Ticket";
import { DialogType } from "components/alove/AloveDialog";
import DeactivateDialog from "layouts/itemPageContainer/components/deactivateDialog";

const MDButton = require("components/MDButton").default;

let navigate;
const host = "dev";
const now = new Date();
let deleteId;

function CustomerSupportTickets({ source }) {
  const pageNumber = "6.1";
  const [controller, dispatch] = useMaterialUIController();
  const { currentBrand, currentRole, currentUser } = controller;
  const isAdmin = currentRole.hasAccess(pageNumber, PermissionType.Manager);
  const isCustomerSupport = source == TicketSource.MobileApp;
  navigate = useNavigate();

  const [mmList, setMmList] = useState([]);
  const [stats, setAdminStats] = useState({
    tickets: 0,
    oneTimeRate: 0,
    assigneeTickets: 0,
    slaExpared: 0,
  });
  const [topics, setTopics] = useState([] as GeneralCode[]);
  const [brands, setBrands] = useState([] as Brand[]);
  const [allUsers, setAllUsers] = useState([] as BoUser[]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setTopics(await getCustomerSupportTopicsDO());
    // const tickets = await getTicketsDO(host);
    // setAdminStats({ ...stats, tickets: tickets?.length });
    setBrands(await getBrandsDO());
    setAllUsers(await getSystemUsersDO(null,null,null,true));
  };

  const getTableData = async (host, filters, page) => {
    if (filters.hasOwnProperty("topic") && filters.topic) {
      const topic = topics.find((t) => t.name === filters.topic);
      if (topic) {
        filters.topic = topic.id;
      }
    }

    if (filters.hasOwnProperty("brand") && filters.brand) {
      const brand = brands.find((b) => b.folder === filters.brand);
      if (brand) {
        filters.brand = brand.id;
      }
    }

    if (!filters.hasOwnProperty("assignee")) {
      filters.assignee = [currentUser.id];
    }

    const tickets = await getTicketsDO(encodeURIComponent(JSON.stringify(filters)), page, source);

    const settings = await getSLASettingsDO();

    let edxpired = 0;
    const updatedTickets = tickets?.map((ticket: Ticket) => {
      const setting = settings.find((s) => s.topicId == ticket.topicId);
      if (setting && [TicketStatus.New, TicketStatus.Open].includes(ticket.status)) {
        const createdDate = new Date(ticket.created);
        const slaDeadline = new Date(createdDate.getTime() + setting.slaFirst * 60 * 60 * 1000);
        const adjustedNow = new Date(now.getTime() - 3 * 60 * 60 * 1000);
        const timeDiff = slaDeadline.getTime() - adjustedNow.getTime();
        const totalMinutesLeft = timeDiff / (1000 * 60);
        const hours = Math.floor(totalMinutesLeft / 60);
        const minutes = Math.floor(Math.abs(totalMinutesLeft % 60));
        if (timeDiff < 0) {
          edxpired++;
        }

        return {
          ...ticket,
          sla: hours + ":" + ("0" + minutes).slice(-2),
        };
      } else {
        return ticket;
      }
    });
    setAdminStats({ ...stats, slaExpared: edxpired, tickets: tickets?.length });

    console.log("updatedTickets:", updatedTickets);

    return updatedTickets;
  };

  const ticketsColumns: TableColumnProps<Ticket>[] = [
    {
      headerName: "#",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Brand",
      field: "brand",
      hidden: () => !isAdmin || currentBrand.id != 100,
      flex: 1,
    },
    {
      headerName: "Created",
      field: "created",
      renderCell: (params) => formatDateOrTime(params.row.created),
      flex: 2,
    },
    {
      headerName: "Topic",
      field: "topicName",
      valueGetter: (v, params) => camelCaseToCapitalizedWords(params?.topic?.name),
      flex: 2,
    },
    {
      headerName: "Content",
      field: "content",
      flex: 5,
    },
    {
      headerName: "Reporter",
      field: "reporter",
      flex: 2,
    },
    {
      headerName: "Status",
      field: "status",
      valueGetter: (v, params) => TicketStatus[params?.status],
      flex: 1,
    },
    {
      headerName: "Priority",
      field: "priority",
      flex: 1,
      hidden: () => isCustomerSupport,
    },
    {
      headerName: "SLA Left",
      field: "sla",
      // renderCell: (params) => console.log('aprams: ', params)
      flex: 2,
      hidden: () => !isCustomerSupport,
    },
    {
      headerName: "Assigned to",
      field: "assignedTo",
      renderCell: (params) =>
        params?.row?.assignedTo?.map((p, i) => {
          const user = allUsers?.find((u) => u.id === p);
          return user?.first_name + " " + user?.last_name + ", ";
        }),
      flex: 5,
    },
    {
      type: "actions",
      field: "actions",
      renderCell: (params) =>
        currentRole.hasAccess(pageNumber, PermissionType.Delete) && (
          <MDButton
            onClick={() => {
              setCurrentDialog(dispatch, DialogType.DeactivateEntitiy);
              deleteId = params.row.id;
            }}
            color="error"
            variant="text"
          >
            Delete
          </MDButton>
        ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Customer Support Tickets" />
      <Box
        sx={{
          "& .urgent": {
            color: "#F4357A",
          },
          "& .hidden": {
            color: "transparent !important",
          },
        }}
      >
        <TablePage
          title="Support Tickets List"
          refresh={refresh}
          actions={
            isCustomerSupport
              ? undefined
              : {
                  addActions: [
                    {
                      label: "Ticket",
                      onClick: () => navigate("/customerSupportTickets/new"),
                      variant: "contained",
                    },
                  ],
                }
          }
          table={{
            columns: ticketsColumns,
            loadNext: getTableData,
            pagination: true,
            lineClick: (row) => navigate(`/customerSupportTickets/${Number(row?.id)}`),
          }}
          filters={[
            {
              label: "ticketID",
              type: "search",
            },
            {
              label: "assignee",
              multiple: true,
              defaultValue: [currentUser.id],
              options: allUsers.map((u) => {
                return { key: u.id, label: u.fullName };
              }),
            },
            {
              label: "topic",
              options: topics.map((t) => {
                return { id: t.id, key: t.name, label: camelCaseToCapitalizedWords(t.name) };
              }),
            },
            {
              label: "brand",
              hidden: !isAdmin || currentBrand.id != 100,
              options: brands.map((b) => {
                return { id: b.id, key: b.folder, label: b.folder };
              }),
            },
            {
              label: "status",
              defaultValue: [TicketStatus.New, TicketStatus.Open],
              multiple: true,
              options: Object.keys(TicketStatus)
                .filter((k) => !isNaN(Number(k)))
                .map((s) => {
                  return { key: Number(s), label: TicketStatus[Number(s)] };
                }),
            },
          ]}
          stats={
            isCustomerSupport
              ? {
                  title: "Support Statistics",
                  fields: [
                    {
                      name: "tickets",
                      title: "Tickets",
                      color: "primary",
                      filterLabel: "status",
                      filterValue: "",
                    },
                    {
                      name: "oneTimeRate",
                      title: "% On time rate",
                    },
                    {
                      name: "assigneeTickets",
                      title: "Tickets per assignee",
                    },
                    {
                      name: "slaExpared",
                      title: "SLA Expired",
                      color: "error",
                      filterValue: "warning",
                    },
                  ],
                  stats,
                }
              : undefined
          }
        />
      </Box>

      <DeactivateDialog
        title="Delete WhatsApp Template"
        content="Are you sure you want to delete this template?"
        confirm="Delete"
        onSubmit={async () => {
          await deleteTicketDO(deleteId);
          addAlert(dispatch, "Ticket deleted successfully");
          setRefresh(!refresh);
        }}
      />
    </DashboardLayout>
  );
}
export default CustomerSupportTickets;
